import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { CustomSnackbarServiceService } from 'src/@vex/components/custom-snackbar/custom-snackbar-service.service';
import { TemplatesService } from 'src/@vex/services/templates.service';
import { TemplatesDialogComponent } from './templates-dialog/templates-dialog.component';

@Component({
  selector: 'vex-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'legacy'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class TemplatesComponent implements OnInit {

  crumbs: any[] = [{ name: 'Templates', link: '/templates', queryParamsValue: false }];
  loadingTable: boolean = true;
  progressBar: boolean = false;
  tableData: any[] = [];
  inputPlaceholder: string = "Search..."
  detailTip: string = "Detay";
  displayedColumns: string[] = ['id', 'thumbnail', 'name', 'description', 'previewVideo', 'clientName', 'actions'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private templateApi: TemplatesService,
    private snack: CustomSnackbarServiceService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.getAllTemplates();
  }

  ngOnInit(): void {
  }

  getAllTemplates() {
    this.loadingTable = true;
    this.templateApi.GetTemplates().subscribe(async data => {
      if (data.status == 'success') {
        this.dataSource = await new MatTableDataSource(data.data.templates);
        this.dataSource.paginator = await this.paginator;
        setTimeout(() => {
          this.dataSource.sort = this.sort;
        }, 500);
        this.loadingTable = false;
      } else {
        this.loadingTable = false;
        this.snack.openSnackBar('Bir hata oluştu!', 'Warn');
      }
    }, err => {
      this.loadingTable = false;
      this.snack.openSnackBar(err.error.title, 'Error');
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog(id: any): void {
    const dialogRef = this.dialog.open(TemplatesDialogComponent, {
      width: '600px',
      height: 'auto',
      data: {
        title: 'Edit Template',
        id: id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result && result.event == 'success') {
        this.snack.openSnackBar('Başarılı.', 'Success');
        this.getAllTemplates();
      }
    });
  }

  openNewDialog() {
    const dialogRef = this.dialog.open(TemplatesDialogComponent, {
      width: '600px',
      height: 'auto',
      data: {
        title: 'New Template'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.event == 'success') {
        this.snack.openSnackBar('Başarılı.', 'Success');
        this.getAllTemplates();
      }
    });
  }

  openComposition(id: number) {
    localStorage.setItem('compId', id.toString());
    this.router.navigate(
      ['compositions'],
      { queryParams: { id: id } }
    )
  }

  deleteTemplate(id: any) {
    if (!confirm('Are you sure?')) return;
    this.templateApi.DeleteTemplate(Number(id)).subscribe(data => {
      this.getAllTemplates();
      if (data.status != 'success') {
        this.snack.openSnackBar('Hata oluştu!', 'Warn');
      } else {
        this.snack.openSnackBar('Başarılı', 'Success');
      }
    })
  }

  calculateCost(id: number) {
    this.templateApi.CalculateCost(id).subscribe({
      next: (data) => {
        if (data.status == 'success') {
          this.snack.openSnackBar('Başarılı', 'Success');
        }
      },
      error: (err) => {
        console.warn(err);
        this.snack.openSnackBar('Hata oluştu!', 'Error');
      }
    })
  }
}
