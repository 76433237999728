import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { AuthGuard } from './pages/auth/auth.guard';
import { BrandsComponent } from './pages/brands/brands.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { CompaniesComponent } from './pages/companies/companies.component';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { AccountsComponent } from './pages/accounts/accounts.component';
import { TemplatesComponent } from './pages/templates/templates.component';
import { CompositionsComponent } from './pages/compositions/compositions.component';
import { LogsComponent } from './pages/app-logs/app-logs.component';
import { RecentTemplatesComponent } from './pages/recent-templates/recent-templates.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: '',
    component: CustomLayoutComponent,
    children: [
      {
        path: 'home',
        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'brands',
        component: BrandsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'clients',
        component: ClientsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'companies',
        component: CompaniesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'categories',
        component: CategoriesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'recent-uploaded-templates',
        component: RecentTemplatesComponent,
        canActivate: [AuthGuard]
      },
      // {
      //   path: 'anibadger',
      //   component: AnibadgerComponent,
      //   canActivate: [AuthGuard]
      // },
      // {
      //   path: 'email',
      //   component: AnibadgerMailComponent,
      //   canActivate: [AuthGuard]
      // },
      {
        path: 'accounts',
        component: AccountsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'templates',
        component: TemplatesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'compositions',
        component: CompositionsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'logs',
        component: LogsComponent,
        canActivate: [AuthGuard]
      }

    ]
  },
  {
    path: '**',
    redirectTo: 'home'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
