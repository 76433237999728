<h1 class="mat-dialog-title">{{data.title}}</h1>
<form [formGroup]="AccountForm" class="flex flex-col">
    <mat-dialog-content>
        <mat-form-field appearance="outline" class="w-full">
            <mat-label translate>Mail</mat-label>
            <input matInput type="text" formControlName="email" placeholder="Mail">
            <mat-error *ngIf="AccountForm.get('email').hasError('required')" translate>Email field is required
            </mat-error>
            <mat-error *ngIf="AccountForm.get('email').hasError('email')">Lütfen geçerli bir mail adresi giriniz
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
            <mat-label translate>Password</mat-label>
            <input matInput type="text" formControlName="password" placeholder="Password">
            <mat-error
                *ngIf="AccountForm.get('password').hasError('minlength') || AccountForm.get('password').hasError('maxlength')"
                translate>
                Password must be between 3-20 characters</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
            <mat-label translate>Full Name</mat-label>
            <input matInput type="text" formControlName="fullName" placeholder="Full Name">
        </mat-form-field>
        <mat-form-field *ngIf="(data.title === 'New Account' && this.AccountForm.get('manager').value == true)"
            appearance="outline" class="w-full">
            <mat-label translate>Role</mat-label>
            <input matInput type="text" formControlName="roleMap" placeholder="Role Map">
        </mat-form-field>
        <section class="flex flex-col">
            <mat-label translate>User Type</mat-label>
            <div class="flex flex-row pt-1 pb-1">
                <mat-checkbox formControlName="user" color="primary" (change)="changeClient()">User</mat-checkbox>
                <mat-checkbox class="ml-2" formControlName="admin" color="primary">Admin</mat-checkbox>
                <mat-checkbox class="ml-2" formControlName="manager" color="primary">Manager</mat-checkbox>
            </div>
            <div *ngIf="data.title == 'New Account'">
                <mat-form-field appearance="outline" class="w-full" *ngIf="AccountForm.get('user').value">
                    <mat-label translate>Clients</mat-label>
                    <mat-select formControlName="clientId">
                        <mat-option *ngFor="let item of clients; let i = index" [value]="item.id">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </section>
        <div *ngIf="data.title == 'Edit Account'">
            <div class="flex flex-col">
                <div class="w-full">
                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label translate>Clients</mat-label>
                        <mat-select formControlName="clientId">
                            <mat-option *ngFor="let item of clients; let i = index" [value]="item.id">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label>Companies</mat-label>
                        <mat-select formControlName="company" multiple>
                            <mat-option *ngFor="let item of companies; let i = index"
                                [value]="item.id">{{item.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex flex-col">
                <div class="w-full">
                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label translate>Brands</mat-label>
                        <mat-select formControlName="brand" multiple>
                            <mat-option *ngFor="let item of brands; let i = index" [value]="item.id">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex flex-col">
                <div class="w-full">
                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label translate>Categories</mat-label>
                        <mat-select formControlName="category" multiple="">
                            <mat-option *ngFor="let item of categories; let i = index" [value]="item.id">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <div mat-dialog-actions class="flex justify-end">
        <button mat-raised-button color="warn" translate (click)="closeDialog()">Cancel</button>
        <button mat-raised-button color="primary" [class.spinner]="loading"
            [disabled]="!AccountForm.valid || loading || (AccountForm.get('user')?.value == false && AccountForm.get('admin')?.value == false&&AccountForm.get('manager')?.value == false)"
            (click)="saveDialog()" translate>Save</button>
    </div>
</form>