import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileValidator } from 'ngx-material-file-input';
import { CustomSnackbarServiceService } from 'src/@vex/components/custom-snackbar/custom-snackbar-service.service';
import { CategoriesService } from 'src/@vex/services/categories.service';
import { TemplatesService } from 'src/@vex/services/templates.service';
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { ClientService } from 'src/@vex/services/client.service';
import { CompaniesService } from 'src/@vex/services/companies.service';
import { BrandsService } from 'src/@vex/services/brands.service';

@Component({
  selector: 'vex-templates-dialog',
  templateUrl: './templates-dialog.component.html',
  styleUrls: ['./templates-dialog.component.scss']
})
export class TemplatesDialogComponent implements OnInit {

  tagsArr = new Set([]);
  TemplateForm: FormGroup;
  newLogo: string | SafeUrl = '';
  loading: boolean = false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  editTemplate: any;
  clients: any;
  companies: any;
  brands: any;
  categories: any;
  progressBar: boolean = false;

  showCompanies: boolean = false;
  showBrands: boolean = false;
  showCategories: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<TemplatesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer,
    private api: TemplatesService,
    private snack: CustomSnackbarServiceService,
    private fb: FormBuilder,
    private clientApi: ClientService,
    private companyApi: CompaniesService,
    private brandApi: BrandsService,
    private categoryApi: CategoriesService,
  ) {
    this.clientApi.GetClients().subscribe({
      next: (data) => {
        this.clients = data.data.clients;
      }
    });
    this.companyApi.GetCompanies().subscribe({
      next: (data) => {
        this.companies = data.data.companies;
      }
    });
    this.brandApi.GetBrands().subscribe({
      next: (data) => {
        this.brands = data.data.brands;
      }
    });
    this.categoryApi.GetCategories().subscribe(data => {
      this.categories = data.data.categories;
    })
    if (this.data.title == 'New Template') {
      this.TemplateForm = this.fb.group({
        name: this.fb.control('', Validators.required),
        thumbnailFile: this.fb.control('', [FileValidator.maxContentSize(5242880), Validators.required]),
        smallImage: this.fb.control('', [FileValidator.maxContentSize(5242880), Validators.required]),
        previewVideoFile: this.fb.control('', [FileValidator.maxContentSize(55242880), Validators.required]),
        templateFile: this.fb.control('', [FileValidator.maxContentSize(990242880), Validators.required]),
        templateDescription: this.fb.control('', Validators.required),
        scheme: this.fb.control('', Validators.required),
        tags: this.fb.array([], Validators.required),
        clientId: this.fb.control('', Validators.required),
        companyId: this.fb.control(null),
        brandId: this.fb.control(null),
        categoryId: this.fb.control(null),
        extensionsScheme: this.fb.control(''),
        exampleSchemeExcelFile: this.fb.control(''),
        cost: this.fb.control('', Validators.pattern('^[0-9]*$'))
      })
    } else {
      this.TemplateForm = this.fb.group({
        name: this.fb.control('', Validators.required),
        thumbnailFile: this.fb.control('', FileValidator.maxContentSize(5242880)),
        smallImage: this.fb.control('', FileValidator.maxContentSize(5242880)),
        previewVideoFile: this.fb.control('', FileValidator.maxContentSize(55242880)),
        templateFile: this.fb.control('', FileValidator.maxContentSize(990242880)),
        templateDescription: this.fb.control('', Validators.required),
        scheme: this.fb.control('', Validators.required),
        tags: this.fb.array([], Validators.required),
        clientId: this.fb.control('', Validators.required),
        companyId: this.fb.control(''),
        brandId: this.fb.control(''),
        categoryId: this.fb.control(''),
        extensionsScheme: this.fb.control(''),
        exampleSchemeExcelFile: this.fb.control(''),
        cost: this.fb.control('', Validators.pattern('^[0-9]*$'))
      })
      this.api.GetTemplateWithId(this.data.id).subscribe({
        next: async (data) => {
          if (data.status == 'success') {
            this.editTemplate = await data.data.template;
            this.newLogo = data.data.template.thumbnail;
            this.TemplateForm.patchValue({
              name: data.data.template.name,
              thumbnailFile: '',
              smallImage: '',
              previewVideoFile: '',
              templateFile: '',
              templateDescription: data.data.template.description,
              scheme: decodeURIComponent(escape(window.atob(data.data.template.scheme))),
              clientId: data.data.template.client.id
            });
            if (data.data.template.extensionsScheme != null) {
              this.TemplateForm.get('extensionsScheme').setValue(decodeURIComponent(escape(window.atob(data.data.template.extensionsScheme))));
            }
            if (data.data.template.company != null) {
              this.TemplateForm.get('companyId').setValue(data.data.template.company.id);
              this.showCompanies = true;
              this.showBrands = true;
              this.showCategories = true;
            }

            if (data.data.template.brand != null) {
              this.TemplateForm.get('brandId').setValue(data.data.template.brand.id);
            }
            if (data.data.template.category != null) {
              this.TemplateForm.get('categoryId').setValue(data.data.template.category.id);
            }
            data.data.template.tagMaps.map(x => {
              this.tagControls.push(this.fb.control(x.tag.name));
            });
          }
        }, error: (err) => {
          console.warn(err);
          this.snack.openSnackBar('Error', 'Error');
        }
      })
    }
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close({ event: 'close' });
  }

  changeImage(ev: any) {
    if (ev) {
      this.newLogo = this.sanitizer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(ev.files[0])
      );
    }
  }

  get tagControls(): FormArray {
    return this.TemplateForm.controls.tags as FormArray;
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || "").trim()) {
      this.tagControls.push(this.fb.control(value));
    }

    if (input) {
      input.value = "";
    }
  }

  showSelects(show: any) {
    switch (show) {
      case 'client':
        this.showCompanies = true;
        break;
      case 'company':
        this.showBrands = true;
        break;
      case 'brand':
        this.showCategories = true;
      default:
        break;
    }
  }

  removeTag(tag: string): void {
    const index = this.tagControls.value.indexOf(tag);
    if (index >= 0) {
      this.tagControls.removeAt(index);
    }
  }

  saveDialog() {
    this.loading = true;
    this.progressBar = true;
    const formData = new FormData();
    formData.append('Id', this.data.id);
    formData.append('Name', this.TemplateForm.get('name').value);
    formData.append('Scheme', this.TemplateForm.get('scheme').value);
    formData.append('ExtensionsScheme', this.TemplateForm.get('extensionsScheme').value);
    if (this.TemplateForm.get('exampleSchemeExcelFile').value != '') {
      formData.append('ExampleSchemeExcelFile', this.TemplateForm.get('exampleSchemeExcelFile').value.files[0])
    }
    if (this.TemplateForm.get('cost').value != '') {
      formData.append('Cost', this.TemplateForm.get('Cost').value);
    }

    this.TemplateForm.get('tags').value.forEach((element, index) => {
      formData.append(`Tags[${index}]`, element);
    });
    formData.append('ClientId', this.TemplateForm.get('clientId').value);

    if (this.TemplateForm.get('companyId').value != null) {
      formData.append('CompanyId', this.TemplateForm.get('companyId').value);
    }

    if (this.TemplateForm.get('brandId').value != null) {
      formData.append('BrandId', this.TemplateForm.get('brandId').value);
    }
    if (this.TemplateForm.get('categoryId').value != null) {
      formData.append('CategoryId', this.TemplateForm.get('categoryId').value);
    }

    if (this.TemplateForm.get('thumbnailFile').value != '') {
      formData.append('ThumbnailFile', this.TemplateForm.get('thumbnailFile').value.files[0]);
    }
    console.log(this.TemplateForm.get('smallImage').value);
    if (this.TemplateForm.get('smallImage').value != '') {
      formData.append('SmallImage', this.TemplateForm.get('smallImage').value.files[0]);
    }
    if (this.TemplateForm.get('previewVideoFile').value != '') {
      formData.append('PreviewVideoFile', this.TemplateForm.get('previewVideoFile').value.files[0]);
    }
    if (this.TemplateForm.get('templateFile').value != '') {
      formData.append('TemplateFile', this.TemplateForm.get('templateFile').value.files[0]);
    }
    if (this.TemplateForm.get('templateDescription').value != '') {
      formData.append('Description', this.TemplateForm.get('templateDescription').value);
    }

    if (this.data.title == 'New Template') {
      this.api.PostTemplate(formData).subscribe(data => {
        this.loading = false;
        if (data.status == 'success') {
          this.dialogRef.close({ event: 'success' });
        } else {
          this.snack.openSnackBar('Tekrar deneyiniz!', 'Warn');
        }
      }, err => {
        console.warn(err);
        this.loading = false;
        this.snack.openSnackBar('Bir hata oluştu!', 'Error');
      })
    } else {
      this.api.PutTemplate(formData).subscribe(data => {
        this.loading = false;
        this.progressBar = false;
        if (data.status == 'success') {
          this.dialogRef.close({ event: 'success' });
        } else {
          this.snack.openSnackBar('Tekrar deneyiniz!', 'Warn');
        }
      }, err => {
        console.warn(err);
        this.loading = false;
        this.progressBar = false;
        this.snack.openSnackBar('Bir hata oluştu!', 'Error');
      })
    }
  }
}
