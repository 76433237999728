import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { HomeComponent } from './pages/home/home.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ApiPrefixInterceptor } from 'src/@vex/utils/api-prefix.interceptor';
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { BreadcrumbsModule } from 'src/@vex/components/breadcrumbs/breadcrumbs.module';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { LoadingModule } from 'src/@vex/components/loading/loading.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatSnackBarModule, MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { ToastrModule } from 'ngx-toastr';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { ProfileComponent } from './pages/profile/profile.component';
import { CustomSnackbarComponent } from 'src/@vex/components/custom-snackbar/custom-snackbar.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { BrandsComponent } from './pages/brands/brands.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { ClientDialogComponent } from './pages/clients/client-dialog/client-dialog.component';
import { CompaniesComponent } from './pages/companies/companies.component';
import { CompanieDialogComponent } from './pages/companies/companie-dialog/companie-dialog.component';
import { BrandsDialogComponent } from './pages/brands/brands-dialog/brands-dialog.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { CategoriesDialogComponent } from './pages/categories/categories-dialog/categories-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatPaginationIntlService } from 'src/@vex/i18n/MatPaginationIntlService';
import { AccountsComponent } from './pages/accounts/accounts.component';
import { AccountsDialogComponent } from './pages/accounts/accounts-dialog/accounts-dialog.component';
import { TemplatesComponent } from './pages/templates/templates.component';
import { TemplatesDialogComponent } from './pages/templates/templates-dialog/templates-dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { CompositionsComponent } from './pages/compositions/compositions.component';
import { CompositionDialogComponent } from './pages/compositions/composition-dialog/composition-dialog.component';
import { pipe } from 'rxjs';
import { ProgressBarModule } from "../@vex/components/progress-bar/progress-bar.module";
import { LogsComponent } from './pages/app-logs/app-logs.component';
import { RecentTemplatesComponent } from './pages/recent-templates/recent-templates.component';
import { RecentUploadedTemplatesDialogComponent } from './pages/recent-templates/recent-uploaded-templates-dialog/recent-uploaded-templates-dialog.component';
import { ByteToMbPipe } from 'src/@vex/pipes/byteToMb.pipe';


@NgModule({
  declarations: [AppComponent, HomeComponent, ProfileComponent, CustomSnackbarComponent, BrandsComponent, ClientsComponent, ClientDialogComponent, CompaniesComponent, CompanieDialogComponent, BrandsDialogComponent, CategoriesComponent, CategoriesDialogComponent, AccountsComponent, AccountsDialogComponent, TemplatesComponent, TemplatesDialogComponent, CompositionsComponent, CompositionDialogComponent, LogsComponent, RecentTemplatesComponent, RecentUploadedTemplatesDialogComponent],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true
    },
    {
      provide: MatSnackBarRef,
      useValue: {}
    },
    {
      provide: MAT_SNACK_BAR_DATA,
      useValue: {}
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginationIntlService,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    CustomSnackbarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    VexModule,
    MatSnackBarModule,
    // NoopAnimationsModule,
    MatProgressSpinnerModule,
    CustomLayoutModule,
    MatChipsModule,
    HttpClientModule,
    PageLayoutModule,
    SecondaryToolbarModule,
    BreadcrumbsModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    LoadingModule,
    FormsModule,
    MaterialFileInputModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatInputModule,
    MatCheckboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTooltipModule,
    MatExpansionModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      timeOut: 2000
    }),
    MatTabsModule,
    TranslateModule.forRoot(),
    MatDialogModule,
    MatMenuModule,
    ProgressBarModule,
    ByteToMbPipe
  ]
})
export class AppModule { }
