<vex-progress-bar *ngIf="progressBar"></vex-progress-bar>
<h1 class="mat-dialog-title">{{data.title}}</h1>
<form [formGroup]="TemplateForm" class="flex flex-col" (submit)="saveDialog()">
    <mat-dialog-content>
        <mat-form-field appearance="outline" class="w-full">
            <mat-label translate>Name</mat-label>
            <input matInput type="text" formControlName="name" placeholder="Name">
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full">
            <ngx-mat-file-input formControlName="thumbnailFile" placeholder="Thumbnail" valuePlaceholder="Thumbnail"
                accept=".png,.jpg,.jpeg" (ngModelChange)="changeImage($event)">
            </ngx-mat-file-input>
            <mat-icon matSuffix svgIcon="mat:folder"></mat-icon>
            <mat-error *ngIf="TemplateForm.get('thumbnailFile').hasError('maxContentSize')" translate>
                This file is too large to be uploaded.
                {{TemplateForm.get('thumbnailFile')?.getError('maxContentSize').maxSize |
                byteFormat}}
                ({{TemplateForm.get('thumbnailFile')?.getError('maxContentSize').actualSize
                | byteFormat}}).
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
            <ngx-mat-file-input formControlName="smallImage" placeholder="Thumbnail" valuePlaceholder="smallImage"
                accept=".png,.jpg,.jpeg" (ngModelChange)="changeImage($event)">
            </ngx-mat-file-input>
            <mat-icon matSuffix svgIcon="mat:folder"></mat-icon>
            <mat-error *ngIf="TemplateForm.get('smallImage').hasError('maxContentSize')" translate>
                This file is too large to be uploaded.
                {{TemplateForm.get('smallImage')?.getError('smallImage').maxSize |
                byteFormat}}
                ({{TemplateForm.get('smallImage')?.getError('smallImage').actualSize
                | byteFormat}}).
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full">
            <ngx-mat-file-input formControlName="previewVideoFile" placeholder="Preview Video"
                valuePlaceholder="Preview Video" accept=".mp4,.mov,.webm,.avi">
            </ngx-mat-file-input>
            <mat-icon matSuffix svgIcon="mat:folder"></mat-icon>
            <mat-error *ngIf="TemplateForm.get('previewVideoFile').hasError('maxContentSize')" translate>
                This file is too large to be uploaded.
                {{TemplateForm.get('previewVideoFile')?.getError('maxContentSize').maxSize |
                byteFormat}}
                ({{TemplateForm.get('previewVideoFile')?.getError('maxContentSize').actualSize
                | byteFormat}}).
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full">
            <ngx-mat-file-input formControlName="templateFile" placeholder="Template File"
                valuePlaceholder="Template File" accept=".zip,.rar">
            </ngx-mat-file-input>
            <mat-icon matSuffix svgIcon="mat:folder"></mat-icon>
            <mat-error *ngIf="TemplateForm.get('templateFile').hasError('maxContentSize')" translate>
                This file is too large to be uploaded.
                {{TemplateForm.get('templateFile')?.getError('maxContentSize').maxSize |
                byteFormat}}
                ({{TemplateForm.get('templateFile')?.getError('maxContentSize').actualSize
                | byteFormat}}).
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full">
            <mat-label translate>Template Description</mat-label>
            <input matInput type="text" formControlName="templateDescription" placeholder="Template Description">
        </mat-form-field>


        <mat-form-field appearance="outline" class="w-full">
            <mat-label translate>Scheme</mat-label>
            <textarea matInput cdkTextareaAutosize type="text" formControlName="scheme" placeholder="Scheme"></textarea>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full">
            <mat-label translate>Extensions Scheme</mat-label>
            <textarea matInput cdkTextareaAutosize type="text" formControlName="extensionsScheme"
                placeholder="Extensions Scheme"></textarea>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full">
            <ngx-mat-file-input formControlName="exampleSchemeExcelFile" placeholder="Excel File"
                valuePlaceholder="Excel File" accept=".xlsx,.xls">
            </ngx-mat-file-input>
            <mat-icon matSuffix svgIcon="mat:folder"></mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full">
            <mat-label translate>Cost</mat-label>
            <input matInput type="text" formControlName="cost" placeholder="Cost">
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full">
            <mat-label translate>Client</mat-label>
            <mat-select formControlName="clientId" (selectionChange)="showSelects('client')">
                <mat-option *ngFor="let item of clients" [value]="item.id">
                    {{item.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full" *ngIf="showCompanies">
            <mat-label translate>Company</mat-label>
            <mat-select formControlName="companyId" (selectionChange)="showSelects('company')">
                <mat-option *ngFor="let item of companies" [value]="item.id">
                    {{item.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full" *ngIf="showBrands">
            <mat-label translate>Brand</mat-label>
            <mat-select formControlName="brandId" (selectionChange)="showSelects('brand')">
                <mat-option *ngFor="let item of brands" [value]="item.id">
                    {{item.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full" *ngIf="showCategories">
            <mat-label translate>Categories</mat-label>
            <mat-select formControlName="categoryId" (selectionChange)="showSelects('category')">
                <mat-option *ngFor="let item of categories" [value]="item.id">
                    {{item.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="w-full" appearance="outline">
            <mat-label translate>Tags</mat-label>
            <mat-chip-list #chipList aria-label="Video keywords" multiple formArrayName="tags">
                <mat-chip *ngFor="let item of TemplateForm.get('tags')?.value" selected [removable]="true"
                    (removed)="removeTag(item)" color="primary">
                    {{item}}
                    <button matChipRemove>
                        <mat-icon svgIcon="mat:cancel"></mat-icon>
                    </button>
                </mat-chip>
                <input placeholder="New tag..." [matChipInputFor]="chipList" [matChipInputAddOnBlur]="true"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addTag($event)">
            </mat-chip-list>
        </mat-form-field>

        <mat-label class="w-full text-center font-bold" *ngIf="newLogo">Thumbnail</mat-label>
        <img [src]="newLogo" alt="thumbnailFile" class="w-full sm:max-w-[300px] mx-auto" *ngIf="newLogo">


    </mat-dialog-content>
    <div mat-dialog-actions class="flex justify-end">
        <button mat-raised-button color="warn" translate (click)="closeDialog()" type="button">Cancel</button>
        <button mat-raised-button color="primary" type="submit" [class.spinner]="loading"
            [disabled]="!TemplateForm.valid || loading" translate>Save</button>
    </div>
</form>