import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/@vex/services/auth.service';
import { PopoverRef } from '../popover/popover-ref';
import { scaleIn400ms } from '../../animations/scale-in.animation';
import { fadeInRight400ms } from '../../animations/fade-in-right.animation';


@Component({
  selector: 'vex-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms
  ]
})
export class UserMenuComponent implements OnInit {

  constructor(private readonly popoverRef: PopoverRef, private auth: AuthService) { }

  ngOnInit(): void {
  }

  closeMenu() {
    this.popoverRef.close();

  }
  close(): void {
    /** Wait for animation to complete and then close */
    this.popoverRef.close();
    this.auth.Logout();
  }
}
