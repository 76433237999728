import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { CustomSnackbarServiceService } from 'src/@vex/components/custom-snackbar/custom-snackbar-service.service';
import { TemplatesService } from 'src/@vex/services/templates.service';
import { RecentUploadedTemplatesDialogComponent } from './recent-uploaded-templates-dialog/recent-uploaded-templates-dialog.component';

@Component({
  selector: 'vex-recent-templates',
  templateUrl: './recent-templates.component.html',
  styleUrls: ['./recent-templates.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
})
export class RecentTemplatesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  crumbs: any[] = [{ name: 'Templates', link: '/templates', queryParamsValue: false }];
  loadingTable: boolean = true;
  progressBar: boolean = false;
  tableData: any[] = [];
  inputPlaceholder: string = "Search..."
  detailTip: string = "Detay";
  displayedColumns: string[] = ['id', 'userID', 'name', 'description', 'campaignName', 'status', 'actions'];
  dataSource: MatTableDataSource<any>;

  constructor(
    private templateApi: TemplatesService,
    private snack: CustomSnackbarServiceService,
    public dialog: MatDialog,
  ) {
    this.getAllTemplates();
  }

  ngOnInit(): void {
  }

  getAllTemplates() {
    this.loadingTable = true;
    this.templateApi.GetRecentUploadedTemplates().subscribe(async data => {
      if (data.status == 'success') {
        this.dataSource = await new MatTableDataSource(data.data.uploadedTemplates);
        this.dataSource.paginator = await this.paginator;
        setTimeout(() => {
          this.dataSource.sort = this.sort;
        }, 500);
        this.loadingTable = false;
      } else {
        this.loadingTable = false;
        this.snack.openSnackBar('Bir hata oluştu!', 'Warn');
      }
    }, err => {
      this.loadingTable = false;
      this.snack.openSnackBar(err.error.title, 'Error');
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog(id: any, name: string, campaignName: string, url: string, userID: number, clientId: number, description: string, status: number): void {
    const dialogRef = this.dialog.open(RecentUploadedTemplatesDialogComponent, {
      width: '600px',
      height: 'auto',
      data: {
        id: id,
        name: name,
        campaignName: campaignName,
        url: url,
        userID: userID,
        clientId: clientId,
        description: description,
        status: status
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result && result.event == 'success') {
        this.snack.openSnackBar('Başarılı.', 'Success');
        this.getAllTemplates();
      }
    });
  }

  deleteTemplate(id: any) {
    if (!confirm('Are you sure?')) return;
    this.templateApi.DeleteUploadedTemplate(Number(id)).subscribe(data => {
      this.getAllTemplates();
      if (data.status != 'success') {
        this.snack.openSnackBar('Hata oluştu!', 'Warn');
      } else {
        this.snack.openSnackBar('Başarılı', 'Success');
      }
    })
  }

  calculateCost(id: number) {
    this.templateApi.CalculateCost(id).subscribe({
      next: (data) => {
        if (data.status == 'success') {
          this.snack.openSnackBar('Başarılı', 'Success');
        }
      },
      error: (err) => {
        console.warn(err);
        this.snack.openSnackBar('Hata oluştu!', 'Error');
      }
    })
  }

  getStatusText(status: number): string {
    switch (status) {
      case 0:
        return 'Beklemede';
      case 1:
        return 'İşlemde';
      case 2:
        return 'Onaylandı';
      case 3:
        return 'Reddedildi';
      default:
        return '';
    }
  }

  downloadFile(rowUrl: string) {
    const fileUrl = rowUrl;
    const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
    const link = document.createElement('a');

    link.href = fileUrl;
    link.setAttribute('download', fileName);
    link.click();
  }


}
