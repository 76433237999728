import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileValidator } from 'ngx-material-file-input';
import { CustomSnackbarServiceService } from 'src/@vex/components/custom-snackbar/custom-snackbar-service.service';
import { ClientService } from 'src/@vex/services/client.service';
import { CompaniesService } from 'src/@vex/services/companies.service';

@Component({
  selector: 'vex-companie-dialog',
  templateUrl: './companie-dialog.component.html',
  styleUrls: ['./companie-dialog.component.scss']
})
export class CompanieDialogComponent implements OnInit {

  Form: FormGroup;
  newLogo: string | SafeUrl = '';
  loading: boolean = false;
  clientsData: any;
  selectedClientId: any;

  constructor(
    public dialogRef: MatDialogRef<CompanieDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer,
    private api: CompaniesService,
    private clientsApi: ClientService,
    private snack: CustomSnackbarServiceService
  ) {
    console.log(data);
    this.clientsApi.GetClients().subscribe(async data => {
      this.clientsData = await data.data.clients;
      console.log(this.data.clientId);
    })
    if (this.data.logo) {
      this.newLogo = this.data.logo;
    }
    if (this.data.title == 'New Company') {
      this.Form = new FormGroup({
        name: new FormControl(this.data.name, Validators.required),
        description: new FormControl(this.data.description, Validators.required),
        shortName: new FormControl(this.data.shortName, Validators.required),
        logo: new FormControl('', [FileValidator.maxContentSize(5242880), Validators.required]),
        clients: new FormControl('', Validators.required)
      })
    } else {
      this.Form = new FormGroup({
        name: new FormControl(this.data.name, Validators.required),
        description: new FormControl(this.data.description, Validators.required),
        shortName: new FormControl(this.data.shortName, Validators.required),
        logo: new FormControl('', FileValidator.maxContentSize(5242880)),
        clients: new FormControl(this.data.clientId, Validators.required)
      })
    }
  }

  ngOnInit(): void {
  }

  saveDialog() {
    this.loading = true;
    let formData: any = new FormData();
    formData.append('Id', this.data.id);
    formData.append('Name', this.Form.get('name').value);
    formData.append('ShortName', this.Form.get('shortName').value);
    formData.append('Description', this.Form  .get('description').value);
    formData.append('ClientId', this.Form.get('clients').value);
    formData.append('Description', 'description');

    if (this.Form.get('logo').value != '') {
      formData.append('LogoFile', this.Form.get('logo').value.files[0])
    }
    if (this.data.title == 'Edit Company') {
      this.api.PutCompanies(formData).subscribe(data => {
        this.loading = false;
        if (data.status != 'success') {
          this.snack.openSnackBar('Tekrar deneyiniz!', 'Warn');
        } else {
          this.snack.openSnackBar('Başarılı.', 'Success');
          this.dialogRef.close({ event: 'success' });
        }
      }), err => {
        this.loading = false;
        console.error(err);
        this.snack.openSnackBar('Bir hata oluştu!', 'Error');
      }
    } else {
      this.api.PostCompanies(formData).subscribe(data => {
        this.loading = false;
        if (data.status != 'success') {
          this.snack.openSnackBar('Tekrar deneyiniz!', 'Warn');
        } else {
          this.snack.openSnackBar('Başarılı.', 'Success');
          this.dialogRef.close({ event: 'success' });
        }
      }), err => {
        this.loading = false;
        console.error(err);
        this.snack.openSnackBar('Bir hata oluştu!', 'Error');
      }
    }

  }

  closeDialog() {
    this.dialogRef.close({ event: 'close' });
  }
  changeImage(ev: any) {
    this.newLogo = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(ev.files[0])
    );
  }
}
