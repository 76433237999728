import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/@vex/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(private http: HttpClient, private auth: AuthService) { }


  GetCompanies(): Observable<any> {
    let clientId = this.auth.clientId;
    return this.http.get<any>(environment.apiUrl + `/Companies?clientId=${clientId}&page=1&size=9999`);
  }

  GetBrands(companyId: number): Observable<any> {
    return this.http.get<any>(environment.apiUrl + `/Brands?companyId=${companyId}&page=1&size=9999`);
  }

  GetCategories(brandId: number): Observable<any> {
    return this.http.get<any>(environment.apiUrl + `/Categories?brandId=${brandId}&page=1&size=9999`);
  }

  GetTemplates(urlString: string): Observable<any> {
    return this.http.get<any>(environment.apiUrl + `/Templates` + urlString);
  }

  GetTemplateDetail(id: number): Observable<any> {
    return this.http.get<any>(environment.apiUrl + `/Templates/${id}`);
  }

  RenderRequest(data: any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/RenderRequests', data);
  }
  GetRenders(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + '/RenderRequests?page=1&size=9999');
  }
  GetRenderDetail(id: number): Observable<any> {
    return this.http.get<any>(environment.apiUrl + `/RenderRequests/${id}`);
  }
  AddRemoveFavorite(id: number): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/Templates/addOrRemoveToFavorites', { templateId: id });
  }
  GetFavorites(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + '/Templates/favoriteTemplates');
  }
  FileDataResources(data: any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/FileDataResources', data);
  }
  FieldsUpdate(data: any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/FileDataResources/fieldsUpdate', data);
  }
  CreateWithFile(data: any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/RenderRequests/createWithFileResource', data);
  }
}
