import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { CustomSnackbarServiceService } from 'src/@vex/components/custom-snackbar/custom-snackbar-service.service';
import { AccountsService } from 'src/@vex/services/accounts.service';
import { AccountsDialogComponent } from './accounts-dialog/accounts-dialog.component';

@Component({
  selector: 'vex-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'legacy'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class AccountsComponent implements OnInit {

  crumbs: any[] = [{ name: 'Accounts', link: '/accounts', queryParamsValue: false }];
  loadingTable: boolean = true;
  tableData: any[] = [];
  inputPlaceholder: string = "Search..."
  detailTip: string = "Detay";
  displayedColumns: string[] = ['id', 'mail', 'fullName', 'type', 'actions'];
  dataSource: MatTableDataSource<any>;
  accountRoles: any[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private api: AccountsService,
    private snack: CustomSnackbarServiceService,
    public dialog: MatDialog
  ) {
    this.getAllAccounts();
  }

  ngOnInit(): void {
  }

  getAllAccounts() {
    this.loadingTable = true;
    this.api.GetAccounts().subscribe(async data => {
      if (data.status == 'success') {
        console.log(data.data.accounts)
        this.dataSource = await new MatTableDataSource(data.data.accounts);
        this.dataSource.paginator = await this.paginator;
        setTimeout(() => {
          this.dataSource.sort = this.sort;
        }, 500);
        this.loadingTable = false;
      } else {
        this.loadingTable = false;
        this.snack.openSnackBar('Bir hata oluştu!', 'Warn');
      }
    }, err => {
      this.loadingTable = false;
      this.snack.openSnackBar(err.error.title, 'Error');
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog(email: any, fullName: any, id: any) {
    const dialogRef = this.dialog.open(AccountsDialogComponent, {
      width: '600px',
      height: 'auto',
      data: {
        title: 'Edit Account',
        email: email,
        fullName: fullName,
        id: id
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.event == 'success') {
        this.snack.openSnackBar('Başarılı.', 'Success');
        this.getAllAccounts();
      }
    });
  }

  openNewDailog() {
    const dialogRef = this.dialog.open(AccountsDialogComponent, {
      width: '600px',
      height: 'auto',
      data: {
        title: 'New Account',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.event == 'success') {
        this.snack.openSnackBar('Başarılı.', 'Success');
        this.getAllAccounts();
      }
    });
  }

  deleteAccount(id: any) {
    if (!confirm('Are you sure?')) return;
    this.api.DeleteAccount(Number(id)).subscribe(data => {
      this.getAllAccounts();
      if (data.status != 'success') {
        this.snack.openSnackBar('Hata oluştu!', 'Warn');
      } else {
        this.snack.openSnackBar('Başarılı', 'Success');
      }
    })
  }
}
