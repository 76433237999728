import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  constructor(private http: HttpClient, private router: Router) { }

  Login(email: string, password: string): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/Auth/Login', {
      email,
      password
    })
  }

  get currentUser() {
    const user = localStorage.getItem(`${this.authLocalStorageToken}`);
    return (user != null || user != undefined || user != '') ? true : false;
  }

  get credInfo() {
    if (localStorage.getItem(`${this.authLocalStorageToken}`) != null) {
      return JSON.parse(localStorage.getItem(`${this.authLocalStorageToken}`) || '');
    } else {
      return '';
    }
  }

  get userName() {
    const username = JSON.parse(localStorage.getItem(`${this.authLocalStorageToken}`));
    return username.data.user.fullName;
  }

  get balanceInfo() {
    const balance = JSON.parse(localStorage.getItem(`${this.authLocalStorageToken}`));
    return balance.data.user.client.balance;
  }

  Logout() {
    localStorage.removeItem(`${this.authLocalStorageToken}`);
    this.router.navigate(['login']);
  }

  get clientId() {
    const data = JSON.parse(localStorage.getItem(`${this.authLocalStorageToken}`));
    console.log(data);
    return data.data.user.client.id;
  }


  SetStorageUser(user: any) {
    return localStorage.setItem(`${this.authLocalStorageToken}`, JSON.stringify(user));
  }

  async TokenIsValid() {
    let status;
    const response = await this.http.get<any>(environment.apiUrl + '/Auth/tokenIsValid').toPromise();
    return (response.status == 'success') ? true : false;
  }
}
