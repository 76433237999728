import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { CustomSnackbarServiceService } from 'src/@vex/components/custom-snackbar/custom-snackbar-service.service';
import { TemplatesService } from 'src/@vex/services/templates.service';
import { CompositionDialogComponent } from './composition-dialog/composition-dialog.component';

@Component({
  selector: 'vex-compositions',
  templateUrl: './compositions.component.html',
  styleUrls: ['./compositions.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'legacy'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class CompositionsComponent implements OnInit {

  crumbs: any[] = [{ name: 'Templates', link: '/templates', queryParamsValue: false }, { name: 'Compositions', link: '/compositions', queryParamsValue: true, queryParams: `id:${localStorage.getItem('compId') || '0'}` }];
  loadingTable: boolean = true;
  tableData: any[] = [];
  inputPlaceholder: string = "Search..."
  detailTip: string = "Detay";
  displayedColumns: string[] = ['id', 'thumbnail', 'name', 'title','estimated', 'cost', 'previewVideo', 'outputType', 'actions'];
  dataSource: MatTableDataSource<any>;
  templateId: number;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(
    private route: ActivatedRoute,
    private api: TemplatesService,
    private router: Router,
    private snack: CustomSnackbarServiceService,
    public dialog: MatDialog,
  ) {
    this.route.queryParams.subscribe(data => {
      if (data.id && data.id != 0) {
        this.templateId = data.id;
        this.getComps(data.id);
      } else {
        this.router.navigate(['templates']);
      }
    })
  }

  ngOnInit(): void {
  }

  getComps(id: number) {
    this.loadingTable = true;
    this.api.GetTemplateWithId(id).subscribe(async templateData => {
      if (templateData.status == 'success') {
        this.dataSource = await new MatTableDataSource(templateData.data.template.compositions);
        this.dataSource.paginator = await this.paginator;
        setTimeout(() => {
          this.dataSource.sort = this.sort;
        }, 500);
        this.loadingTable = false;
      }
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  deleteComp(id: any) {
    if (!confirm('Are you sure?')) return;
    this.api.DeleteComp(this.templateId, id).subscribe(data => {
      this.getComps(this.templateId);
      if (data.status != 'success') {
        this.snack.openSnackBar('Hata oluştu!', 'Warn');
      } else {
        this.snack.openSnackBar('Başarılı', 'Success');
      }
    })
  }

  openNewDialog() {
    const dialogRef = this.dialog.open(CompositionDialogComponent, {
      width: '600px',
      height: 'auto',
      data: {
        title: 'New Composition',
        id: this.templateId
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.event == 'success') {
        this.snack.openSnackBar('Başarılı.', 'Success');
        this.getComps(this.templateId);
      }
    });
  }

  openDialog(id: any, name: any, title: any, desc: any,estimatedTime:any, cost: any, outputType: any) {
    const dialogRef = this.dialog.open(CompositionDialogComponent, {
      width: '600px',
      height: 'auto',
      data: {
        title: 'Edit Composition',
        templateId: this.templateId,
        id: id,
        name: name,
        dataTitle: title,
        desc: desc,
        estimatedTime:estimatedTime,
        cost: cost,
        outputType: outputType
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.event == 'success') {
        this.snack.openSnackBar('Başarılı.', 'Success');
        this.getComps(this.templateId);
      }
    });
  }
}
