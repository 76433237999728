import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http: HttpClient) { }

  GetClients(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + '/Clients?page=1&size=999999999');
  }
  PutClients(data: any): Observable<any> {
    return this.http.put<any>(environment.apiUrl + '/Clients', data);
  }
  PostClient(data: any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/Clients', data);
  }
  DeleteClient(id: number): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + '/Clients/' + id);
  }

  ByteToMb(maxUploadSize: number): number {
    return maxUploadSize / (1024 * 1024);
  }

  MbToByte(maxUploadSize: number): number {
    return maxUploadSize * 1024 * 1024;
  }
}
