import { Component, OnInit, ViewChild } from '@angular/core';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { CustomSnackbarServiceService } from 'src/@vex/components/custom-snackbar/custom-snackbar-service.service';
import { LogService } from 'src/@vex/services/log.service';

@Component({
  selector: 'vex-logs',
  templateUrl: './app-logs.component.html',
  styleUrls: ['./app-logs.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'legacy'
      } as MatFormFieldDefaultOptions
    }
  ]
})

export class LogsComponent implements OnInit {
  crumbs: any[] = [{ name: 'Logs', link: '/logs', queryParamsValue: false }];
  loadingTable: boolean = true;
  inputPlaceholder: string = "Search..."
  detailTip: string = "Detay";
  clientSearch:any;
  displayedColumns: string[] = ['id', 'mail', 'message', 'description', 'createDate', 'type'];
  adminArray:any = [];
  clientArray:any = [];
  dataAdmin : MatTableDataSource<any>;
  dataClient: MatTableDataSource<any>;
  statusCheck:any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('paginatorClient') paginatorClient: MatPaginator;

  @ViewChild('firstSort') sort: MatSort;
  @ViewChild('secondSort') sortClient: MatSort;

  constructor(
    private api: LogService,
    private snack: CustomSnackbarServiceService,
  ) { }

  ngOnInit(): void {
    this.GetAllLogs();
  }

  GetAllLogs() {
    this.loadingTable = true;
    this.api.GetAllLog().subscribe({
      next: async (data) => {
       await data.map((element:any)=>{
          if(element.status == "admin"){
            this.adminArray.push(element);
            this.adminArray.reverse();
            this.dataAdmin = new MatTableDataSource(this.adminArray);
            this.dataAdmin.paginator = this.paginator;
            setTimeout(() => {
              this.dataAdmin.sort = this.sort;
            }, 500);
            this.loadingTable = false;
          }else if(element.status == "client"){
            this.clientArray.push(element);
            this.clientArray.reverse();
            this.dataClient = new MatTableDataSource(this.clientArray);
            this.dataClient.paginator = this.paginatorClient;
            setTimeout(() => {
              this.dataClient.sort = this.sortClient;
            }, 500);
            this.loadingTable = false;
          }
        })
      },
      error: (err) => {
        this.loadingTable = false;
        console.warn(err);
        this.snack.openSnackBar('Error', 'Error');
      }
    })
  }

  
  applyFilterAdmin(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataAdmin.filter = filterValue.trim().toLowerCase();
    if (this.dataAdmin.paginator) {
      this.dataAdmin.paginator.firstPage();
    }
  }

  applyFilterClient(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataClient.filter = filterValue.trim().toLowerCase();
    if (this.dataClient.paginator) {
      this.dataClient.paginator.firstPage();
    }
  }

  

}
