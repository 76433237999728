<div class="container py-gutter">
    <div class="card overflow-hidden">
        <div class="h-64 relative overflow-hidden">
            <img class="w-full h-full object-cover" src="assets/img/demo/landscape.jpg">
            <div class="absolute bg-contrast-black opacity-25 top-0 right-0 bottom-0 left-0 w-full h-full z-0"></div>
            <img class="avatar h-24 w-24 absolute top-6 ltr:left-4 rtl:right-4 sm:hidden"
                src="assets/img/demo/montent-logo.jfif">
        </div>
        <div class="z-10 relative -mt-20 px-gutter flex items-center">
            <img @scaleIn
                class="avatar h-24 w-24 flex-none align-start hidden sm:block border-2 border-white relative  sm:top-4"
                src="assets/img/demo/montent-logo.jfif">
            <div class="max-w-full flex-auto sm:ltr:ml-6 sm:rtl:mr-6">
                <div class="h-16 flex items-end">
                    <h1 @fadeInRight class="headline text-contrast-white pb-2 m-0">{{username}}</h1>
                </div>
            </div>
        </div>
        <mat-tab-group mat-align-tabs="start" class="vex-tabs vex-tabs-dense border-0 -mt-3">
            <mat-tab [label]="'Timeline'|translate">
                <div id="section1">
                    <div class=" flex flex-col md:flex-row md:items-start">
                        <div class="flex-auto">
                            <div class="card">
                                <div class="px-gutter py-3">
                                    <h2 class="title m-0" translate>Timeline</h2>
                                </div>
                                <div class="px-gutter" @scaleIn>
                                    <div class="py-3 flex items-center">
                                        <div class="py-3 flex items-center">
                                            <div @scaleIn
                                                class="w-10 h-10 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                                                <mat-icon class="icon-sm" svgIcon="mat:monetization_on"></mat-icon>
                                            </div>
                                            <div class="flex flex-col ">
                                                <span class="font-bold" translate>Balance</span>
                                                <span>{{balance}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="px-gutter py-2" @scaleIn>
                                    <h1 class="m-0 title" translate>Past Transactions</h1>
                                    <div class="py-3">
                                        <div class="card overflow-auto">
                                            <table @stagger mat-table *ngIf="!loadingTable" [dataSource]="dataSource"
                                                matSort>
                                                <ng-container matColumnDef="id">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                        class="uppercase font-bold">
                                                        ID </th>
                                                    <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="title">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                        class="uppercase font-bold">
                                                        <span translate>Title</span>
                                                    </th>
                                                    <td mat-cell *matCellDef="let row"> {{row.title}} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="createDate">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                        class="uppercase font-bold">
                                                        <span translate>İşlem Tarihi</span>
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        {{row.createDate | date:'dd/MM/yyyy hh:mm' }} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="actions">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                        class="uppercase font-bold" translate>
                                                        <span translate>Processes</span>
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">
                                                        <div *ngFor="let item of checkIdArray">
                                                            <button *ngIf="item==row.id" mat-icon-button
                                                                matTooltip={{detailTip|translate}} color="primary"
                                                                (click)="navigateRenderDetail(row.id)">
                                                                <mat-icon svgIcon="mat:video_settings"></mat-icon>
                                                            </button>
                                                        </div>
                                                        <div *ngFor="let item2 of doneIdArray">
                                                            <div *ngIf="item2==row.id">
                                                                <mat-chip-list>
                                                                    <mat-chip color="primary" class="text-xs" selected>
                                                                        {{'In Progress'|translate}}
                                                                    </mat-chip>
                                                                </mat-chip-list>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </ng-container>
                                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                <tr @fadeInUp mat-row *matRowDef="let row; columns: displayedColumns;"
                                                    class="hover:bg-hover trans-ease-out"></tr>
                                            </table>
                                        </div>
                                        <div class="py-3 flex items-center">
                                            <!-- <div @scaleIn
                                            class="w-10 h-10 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                                            <mat-icon class="icon-sm" svgIcon="mat:lock"></mat-icon>
                                        </div>
                                        <div @fadeInRight>
                                            <p class="m-0 body-1" translate>Password</p>
                                            <input type="{{typeInput}}"
                                                class="bg-transparent border-0 m-0 caption text-hint password-input"
                                                readonly value="Montent1234">
                                            <mat-icon class="icon-sm eye cursor-pointer" (click)="showPassword()"
                                                svgIcon="mat:visibility"></mat-icon>
                                        </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab [label]="'Settings'|translate">
                <div id="section1" @scaleIn>
                    <div class=" flex flex-col md:flex-row md:items-start">
                        <div class="flex-auto">
                            <div class="card">
                                <div class="px-gutter py-3">
                                    <h2 class="title m-0" translate>Settings</h2>
                                </div>
                                <div class="px-gutter py-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
                                    <div class="py-3 flex ">
                                        <div @scaleIn
                                            class="w-10 h-10 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                                            <mat-icon class="icon-sm" svgIcon="mat:person"></mat-icon>
                                        </div>
                                        <form [formGroup]="newUsernameForm" (ngSubmit)="saveUsername()">
                                            <div @fadeInRight>
                                                <div class="flex-col">
                                                    <div class="mb-2">
                                                        {{'Change Username'|translate}}
                                                    </div>
                                                    <mat-form-field class="example-full-width" appearance="fill">
                                                        <mat-label>{{'Username' | translate}}</mat-label>
                                                        <input formControlName="newUser"
                                                            (change)="cleanForm(newUsernameForm)" matInput #newusername
                                                            maxlength="256" [placeholder]="'New Username' | translate">
                                                        <mat-hint align="start">{{newusername.value.length}} / 15
                                                        </mat-hint>
                                                        <mat-error
                                                            *ngIf="newUsernameForm.get('newUser')?.hasError('required')">
                                                            {{'New Username'|translate}} <strong>
                                                                {{'Required'|translate}}</strong>
                                                        </mat-error>
                                                        <mat-error
                                                            *ngIf="newUsernameForm.get('newUser').hasError('minlength') || newUsernameForm.get('newUser').hasError('maxlength')"
                                                            translate>
                                                            Username must be between 3-20 characters</mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <button mat-raised-button class="mt-3" type="submit"
                                                    [disabled]="newUsernameForm.invalid || loadingUsername"
                                                    color="primary" [class.spinner]="loadingUsername">
                                                    {{'Save'|translate}} </button>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="py-3 flex ">
                                        <div @scaleIn
                                            class="w-10 h-10 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
                                            <mat-icon class="icon-sm" svgIcon="mat:lock"></mat-icon>
                                        </div>
                                        <form [formGroup]="newUserInfo" (ngSubmit)="savePassword()">
                                            <div @fadeInRight>
                                                <div class="flex-col">
                                                    <div class="mb-2">
                                                        {{'Change Password'|translate}}
                                                    </div>
                                                    <div>
                                                        <mat-form-field class="example-full-width" appearance="fill">
                                                            <mat-label>{{'Old Password'|translate}}</mat-label>
                                                            <input [type]="(oldPasswordShow)?'password':'text'" matInput
                                                                #oldpassword maxlength="256"
                                                                (change)="cleanForm(newUserInfo)"
                                                                [placeholder]="'Old Password' | translate"
                                                                formControlName="oldPassword">
                                                            <div @scaleIn
                                                                class="w-4 h-4 rounded-full cursor-pointer bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex absolute right-0 top-0">
                                                                <mat-icon *ngIf="!oldPasswordShow" class="icon-sm"
                                                                    (click)="oldPasswordShow=!oldPasswordShow"
                                                                    svgIcon="mat:visibility_off"></mat-icon>
                                                                <mat-icon *ngIf="oldPasswordShow" class="icon-sm"
                                                                    (click)="oldPasswordShow=!oldPasswordShow"
                                                                    svgIcon="mat:visibility"></mat-icon>
                                                            </div>
                                                            <mat-hint align="start">{{oldpassword.value.length}} / 15
                                                            </mat-hint>
                                                            <mat-error
                                                                *ngIf="newUserInfo.get('oldPassword')?.hasError('required')">
                                                                {{'Old Password'|translate}} <strong>
                                                                    {{'Required'|translate}}</strong>
                                                            </mat-error>
                                                            <mat-error
                                                                *ngIf="newUserInfo.get('oldPassword').hasError('minlength') || newUserInfo.get('oldPassword').hasError('maxlength')"
                                                                translate>
                                                                Password must be between 3-20 characters</mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <div @scaleIn class="pt-4">
                                                        <mat-form-field class="example-full-width" appearance="fill">
                                                            <mat-label>{{'New Password'|translate}}</mat-label>
                                                            <input [type]="(newPasswordShow)?'password':'text'" matInput
                                                                #password maxlength="256"
                                                                (change)="cleanForm(newUserInfo)"
                                                                [placeholder]="'New Password' | translate"
                                                                formControlName="newPass">
                                                            <div @scaleIn
                                                                class="w-4 h-4 rounded-full cursor-pointer bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex absolute right-0 top-0">
                                                                <mat-icon *ngIf="!newPasswordShow" class="icon-sm"
                                                                    (click)="newPasswordShow=!newPasswordShow"
                                                                    svgIcon="mat:visibility_off"></mat-icon>
                                                                <mat-icon *ngIf="newPasswordShow" class="icon-sm"
                                                                    (click)="newPasswordShow=!newPasswordShow"
                                                                    svgIcon="mat:visibility"></mat-icon>
                                                            </div>
                                                            <mat-hint align="start">{{password.value.length}} / 15
                                                            </mat-hint>
                                                            <mat-error
                                                                *ngIf="newUserInfo.get('newPass')?.hasError('required')">
                                                                {{'New Password'|translate}} <strong>
                                                                    {{'Required'|translate}}</strong>
                                                            </mat-error>
                                                            <mat-error
                                                                *ngIf="newUserInfo.get('newPass').hasError('minlength') || newUserInfo.get('newPass').hasError('maxlength')"
                                                                translate>
                                                                Password must be between 3-20 characters</mat-error>
                                                        </mat-form-field>
                                                    </div>
                                                    <mat-form-field class="example-full-width pt-4" appearance="fill"
                                                        @fadeInRight>
                                                        <mat-label>{{'Confirm Password'|translate}}</mat-label>
                                                        <input [type]="(confirmPasswordShow)?'password':'text'" matInput
                                                            (change)="cleanForm(newUserInfo)" #confirmpassword
                                                            maxlength="256"
                                                            [placeholder]="'Confirm Password' | translate"
                                                            formControlName="rePassword">
                                                        <mat-hint align="start">{{confirmpassword.value.length}} / 15
                                                        </mat-hint>
                                                        <div @scaleIn
                                                            class="w-4 h-4 rounded-full cursor-pointer bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex absolute right-0 top-0">
                                                            <mat-icon *ngIf="!confirmPasswordShow" class="icon-sm"
                                                                (click)="confirmPasswordShow=!confirmPasswordShow"
                                                                svgIcon="mat:visibility_off"></mat-icon>
                                                            <mat-icon *ngIf="confirmPasswordShow" class="icon-sm"
                                                                (click)="confirmPasswordShow=!confirmPasswordShow"
                                                                svgIcon="mat:visibility"></mat-icon>
                                                        </div>
                                                        <mat-error
                                                            *ngIf="newUserInfo.get('rePassword')?.hasError('required')">
                                                            {{'Confirm Password'|translate}} <strong>
                                                                {{'Required'|translate}}</strong>
                                                        </mat-error>
                                                        <mat-error
                                                            *ngIf="newUserInfo.get('rePassword').hasError('minlength') || newUserInfo.get('rePassword').hasError('maxlength')"
                                                            translate>
                                                            Password must be between 3-20 characters</mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <button mat-raised-button class="mt-2" type="submit" color="primary"
                                                    [disabled]="newUserInfo.invalid||newPassword !== confirmPassword || loadingButton"
                                                    [class.spinner]="loadingButton">
                                                    {{'Save'|translate}}</button>
                                                <mat-error *ngIf="newPassword!==confirmPassword">
                                                    {{'Passwords Must Be Same'|translate}}
                                                </mat-error>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>