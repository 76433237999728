<vex-progress-bar *ngIf="progressBar"></vex-progress-bar>
<h1 class="mat-dialog-title">{{data.title}}</h1>
<form [formGroup]="TemplateForm" class="flex flex-col" (submit)="saveDialog()">
    <mat-dialog-content>

        <mat-form-field appearance="outline" class="w-full">
            <mat-label translate>Description</mat-label>
            <input matInput type="text" formControlName="description" placeholder="Description">
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
            <mat-label translate>Status</mat-label>
            <mat-select formControlName="status">
                <mat-option [value]="0" [attr.selected]="TemplateForm.get('status').value === 0? true : null">
                    {{'Beklemede' | translate}}
                </mat-option>
                <mat-option [value]="1" [attr.selected]="TemplateForm.get('status').value === 1 ? true : null">
                    {{'İşlemde' | translate}}
                </mat-option>
                <mat-option [value]="2" [attr.selected]="TemplateForm.get('status').value === 2 ? true : null">
                    {{'Onaylandı' | translate}}
                </mat-option>
                <mat-option [value]="3" [attr.selected]="TemplateForm.get('status').value === 3 ? true : null">
                    {{'Reddedildi' | translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </mat-dialog-content>
    <div mat-dialog-actions class="flex justify-end">
        <button mat-raised-button color="warn" translate (click)="closeDialog()" type="button">Cancel</button>
        <button mat-raised-button color="primary" type="submit" [class.spinner]="loading"
            [disabled]="!TemplateForm.valid || loading" translate>Save</button>
    </div>
</form>