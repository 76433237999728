<vex-page-layout>
    <vex-page-layout-header class="pb-16 flex flex-col items-start justify-center">
        <div class="w-full flex flex-col sm:flex-row justify-between container">
            <div>
                <h1 class="title mt-0 mb-1">Accounts Table</h1>
                <vex-breadcrumbs [crumbs]="crumbs"></vex-breadcrumbs>
            </div>
        </div>
    </vex-page-layout-header>
    <vex-page-layout-content class="-mt-6">
        <div class="container -mt-16">
            <div class="card overflow-auto">
                <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
                    <h2
                        class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
                        <span translate>Accounts</span>
                    </h2>
                    <div
                        class="bg-foreground rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
                        <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
                        <input (keyup)="applyFilter($event)"
                            class="px-4 py-2 border-0 outline-none w-full bg-transparent"
                            placeholder={{inputPlaceholder|translate}} type="search" #input>
                    </div>
                    <span class="flex-1"></span>
                    <button class="ml-4 flex-none" color="primary" mat-mini-fab matTooltip="Add Account" type="button"
                        (click)="openNewDailog()">
                        <mat-icon svgIcon="mat:add"></mat-icon>
                    </button>
                </div>
                <table @stagger *ngIf="!loadingTable" mat-table [dataSource]="dataSource" matSort #firstSort="matSort">
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold">
                            ID </th>
                        <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                    </ng-container>
                    <ng-container matColumnDef="mail">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold">
                            <span translate>Mail</span>
                        </th>
                        <td mat-cell *matCellDef="let row"> {{row.email }} </td>
                    </ng-container>
                    <ng-container matColumnDef="fullName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold">
                            <span translate>Full Name</span>
                        </th>
                        <td mat-cell *matCellDef="let row"> {{row.fullName }} </td>
                    </ng-container>
                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold">
                            <span translate>Type</span>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-chip-list>
                                <ng-container *ngFor="let item of row.roles">
                                    <mat-chip color="primary" selected *ngIf="item =='user'">User</mat-chip>
                                    <mat-chip color="warn" selected *ngIf="item =='admin'">Admin</mat-chip>
                                    <mat-chip color="accent" selected
                                        *ngIf="item =='user::client-manager'">Manager</mat-chip>
                                </ng-container>
                            </mat-chip-list>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold" translate>
                            <span translate>Actions</span>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button color="primary" matTooltip="Edit Brand"
                                (click)="openDialog(row.email, row.fullName, row.id)">
                                <mat-icon svgIcon="mat:edit"></mat-icon>
                            </button>
                            <button mat-icon-button color="warn" matTooltip="Delete Brand"
                                (click)="deleteAccount(row.id)">
                                <mat-icon svgIcon="mat:delete"></mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr @fadeInUp mat-row *matRowDef="let row; columns: displayedColumns;"
                        class="hover:bg-hover trans-ease-out"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4" translate><span translate>No data matching the filter
                                <span>"{{input.value}}"</span></span></td>
                    </tr>
                </table>
                <vex-loading [size]="100" *ngIf="loadingTable" class="w-100 flex justify-center items-center">
                </vex-loading>
                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of datas"
                    class="sticky left-0" translate>
                </mat-paginator>
            </div>
        </div>
    </vex-page-layout-content>
</vex-page-layout>