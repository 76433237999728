import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { FileValidator } from 'ngx-material-file-input';
import { CustomSnackbarServiceService } from 'src/@vex/components/custom-snackbar/custom-snackbar-service.service';
import { TemplatesService } from 'src/@vex/services/templates.service';

@Component({
  selector: 'vex-composition-dialog',
  templateUrl: './composition-dialog.component.html',
  styleUrls: ['./composition-dialog.component.scss']
})
export class CompositionDialogComponent implements OnInit {

  CompositionForm: any;
  newLogo: any;
  loading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<CompositionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: TemplatesService,
    private snack: CustomSnackbarServiceService,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer
  ) {
    if (this.data.title == 'New Composition') {
      this.CompositionForm = this.fb.group({
        name: this.fb.control('', Validators.required),
        title: this.fb.control('', Validators.required),
        description: this.fb.control('', Validators.required),
        renderTime: this.fb.control('', Validators.required),
        cost: this.fb.control('', Validators.required),
        thumbnailFile: this.fb.control('', [FileValidator.maxContentSize(5242880), Validators.required]),
        previewVideoFile: this.fb.control('', [FileValidator.maxContentSize(55242880), Validators.required]),
        outputType: this.fb.control('', Validators.required),
      });
    } else {
      this.CompositionForm = this.fb.group({
        name: this.fb.control(this.data.name, Validators.required),
        title: this.fb.control(this.data.dataTitle, Validators.required),
        description: this.fb.control(this.data.desc, Validators.required),
        renderTime: this.fb.control(this.data.estimatedTime, Validators.required),
        cost: this.fb.control(this.data.cost, Validators.required),
        thumbnailFile: this.fb.control('', FileValidator.maxContentSize(5242880)),
        previewVideoFile: this.fb.control('', FileValidator.maxContentSize(55242880)),
        outputType: this.fb.control(this.data.outputType.toString(), Validators.required),
      });
    }
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close({ event: 'close' });
  }

  changeImage(ev: any) {
    this.newLogo = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(ev.files[0])
    );
  }

  saveDialog() {
    this.loading = true;
    let formData = new FormData();
    formData.append('Name', this.CompositionForm.get('name').value);
    formData.append('Title', this.CompositionForm.get('title').value);
    formData.append('Description', this.CompositionForm.get('description').value);
    formData.append('estimatedTime', this.CompositionForm.get('renderTime').value);
    formData.append('Cost', this.CompositionForm.get('cost').value);
    formData.append('OutputType', this.CompositionForm.get('outputType').value);


    if (this.CompositionForm.get('thumbnailFile').value != '') {
      formData.append('ThumbnailFile', this.CompositionForm.get('thumbnailFile').value.files[0]);
    }
    if (this.CompositionForm.get('previewVideoFile').value != '') {
      formData.append('PreviewVideoFile', this.CompositionForm.get('previewVideoFile').value.files[0]);
    }

    if (this.data.title == 'New Composition') {
      this.api.PostComp(formData, this.data.id).subscribe({
        next: (data) => {
          this.loading = false;
          if (data.status == 'success') {
            this.dialogRef.close({ event: 'success' });
          } else {
            this.snack.openSnackBar('Tekrar deneyiniz!', 'Warn');
          }
        }, error: (err) => {
          console.warn(err);
          this.loading = false;
          this.snack.openSnackBar('Hata oluştu!', 'Error');
        }
      })
    } else {
      formData.append('Id', this.data.id);
      this.api.PutComp(formData, this.data.templateId).subscribe({
        next: (data) => {
          this.loading = false;
          if (data.status == 'success') {
            this.dialogRef.close({ event: 'success' });
          } else {
            this.snack.openSnackBar('Tekrar deneyiniz!', 'Warn');
          }
        }, error: (err) => {
          console.warn(err);
          this.loading = false;
          this.snack.openSnackBar('Hata oluştu!', 'Error');
        }
      })
    }

  }
}
