import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {

  constructor(private http: HttpClient) { }

  GetTemplates(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + '/Templates?page=1&size=999999');
  }

  DeleteTemplate(id: any): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + '/Templates/' + id);
  }

  PostTemplate(data: any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/Templates', data);
  }

  PutTemplate(data: any): Observable<any> {
    return this.http.put<any>(environment.apiUrl + '/Templates', data);
  }

  GetTemplateWithId(id: number): Observable<any> {
    return this.http.get<any>(environment.apiUrl + '/Templates/' + id);
  }

  DeleteComp(templateId: any, compId: any): Observable<any> {
    return this.http.delete<any>(`${environment.apiUrl}/${templateId}/Compositions/${compId}`);
  }

  PostComp(data: any, tempId: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/${tempId}/Compositions`, data);
  }

  PutComp(data: any, tempId: any): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/${tempId}/Compositions`, data);
  }

  CalculateCost(id: number): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/Templates/' + id.toString() + '/calculateCost', id);
  }

  GetRecentUploadedTemplates() {
    return this.http.get<any>(environment.apiUrl + '/Templates/GetUploadedTemplates?page=1&size=999999');
  }

  UpdateUploadedTemplateStatus(data: any) {
    return this.http.put<any>(environment.apiUrl + '/Templates/PutUploadedTemplateStatus', data);
  }

  DeleteUploadedTemplate(id: any) {
    return this.http.delete<any>(environment.apiUrl + `/Templates/DeleteUploadedTemplate${id}`);
  }

}
