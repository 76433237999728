<vex-page-layout>
    <vex-page-layout-header class="pb-16 flex flex-col items-start justify-center">
        <div class="w-full flex flex-col sm:flex-row justify-between container">
            <div>
                <h1 class="title mt-0 mb-1" translate>Son Yüklenen Şablonlar</h1>
                <vex-breadcrumbs [crumbs]="crumbs"></vex-breadcrumbs>
            </div>
        </div>
    </vex-page-layout-header>
    <vex-page-layout-content class="-mt-6">
        <div class="container -mt-16">
            <div class="card overflow-auto">
                <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
                    <h2
                        class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
                        <span translate>Son Yüklenen Şablonlar</span>
                    </h2>
                    <div
                        class="bg-foreground rounded-full px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
                        <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
                        <input (keyup)="applyFilter($event)"
                            class="px-4 py-2 border-0 outline-none w-full bg-transparent"
                            placeholder={{inputPlaceholder|translate}} type="search" #input>
                    </div>
                    <span class="flex-1"></span>
                </div>
                <table @stagger *ngIf="!loadingTable" mat-table [dataSource]="dataSource" matSort #firstSort="matSort">
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold">
                            ID </th>
                        <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                    </ng-container>
                    <ng-container matColumnDef="userID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold">
                            <span translate>userID</span>
                        </th>
                        <td mat-cell *matCellDef="let row"> {{row.userId }} </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold">
                            <span translate>Name</span>
                        </th>
                        <td mat-cell *matCellDef="let row"> {{row.name }} </td>
                    </ng-container>
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold">
                            <span translate>description</span>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            {{ row.description ? row.description.slice(0,30)+'...' : 'Şu an açıklama yok' }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="campaignName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold">
                            <span translate>Campaign Name</span>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <span translate>{{row.campaignName }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold">
                            <span translate>Status</span>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <span class="badge" translate [ngClass]="{
                                'yellow': row.status === 0,
                                'blue': row.status === 1,
                                'green': row.status === 2,
                                'red': row.status === 3
                            }">{{getStatusText(row.status)|translate }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold" translate>
                            <span translate>Actions</span>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <button [matMenuTriggerFor]="actionMenu" mat-icon-button type="button" color="primary">
                                <mat-icon svgIcon="mat:more_vert"></mat-icon>
                            </button>
                            <mat-menu #actionMenu="matMenu" overlapTrigger="false" xPosition="before" yPosition="below">
                                <button mat-menu-item (click)="downloadFile(row.url)">
                                    <mat-icon svgIcon="mat:download" color="primary"></mat-icon>
                                    <span translate>Download Template</span>
                                </button>
                                <button mat-menu-item
                                    (click)="openDialog(row.id,row.name,row.campaignName,row.url,row.userId,row.clientId,row.description,row.status)">
                                    <mat-icon svgIcon="mat:edit" color="primary"></mat-icon>
                                    <span translate>Edit</span>
                                </button>
                                <button mat-menu-item (click)="deleteTemplate(row.id)">
                                    <mat-icon svgIcon="mat:delete" color="warn"></mat-icon>
                                    <span translate>Delete Template</span>
                                </button>
                            </mat-menu>

                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr @fadeInUp mat-row *matRowDef="let row; columns: displayedColumns;"
                        class="hover:bg-hover trans-ease-out"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4" translate><span translate>No data matching the filter
                                <span>"{{input.value}}"</span></span></td>
                    </tr>
                </table>
                <vex-loading [size]="100" *ngIf="loadingTable" class="w-100 flex justify-center items-center">
                </vex-loading>
                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of datas"
                    class="sticky left-0" translate>
                </mat-paginator>
            </div>
        </div>
    </vex-page-layout-content>
</vex-page-layout>