import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor(private http: HttpClient) { }

  GetCompanies(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + '/Companies?page=1&size=999999999');
  }
  DeleteCompanie(id: any): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + '/Companies/' + id);
  }
  PostCompanies(data: any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/Companies', data);
  }
  PutCompanies(data: any): Observable<any> {
    return this.http.put<any>(environment.apiUrl + '/Companies', data);
  }

  GetCompaniesId(id: number): Observable<any> {
    return this.http.get<any>(environment.apiUrl + '/Companies?page=1&size=999999999&clientId=' + id.toString());
  }
}
