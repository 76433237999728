import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { CustomSnackbarServiceService } from 'src/@vex/components/custom-snackbar/custom-snackbar-service.service';
import { ClientService } from 'src/@vex/services/client.service';
import { ClientDialogComponent } from './client-dialog/client-dialog.component';

@Component({
  selector: 'vex-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'legacy'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class ClientsComponent implements OnInit {
  crumbs: any[] = [{ name: 'Clients', link: '/clients', queryParamsValue: false }];
  loadingTable: boolean = true;
  tableData: any[] = [];
  inputPlaceholder: string = "Search..."
  detailTip: string = "Detay";
  displayedColumns: string[] = ['id', 'logo', 'name', 'balance', 'shortName', 'maxUploadSize', 'actions'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private clientApi: ClientService, private snack: CustomSnackbarServiceService, public dialog: MatDialog) {
    this.getAllClients();
  }

  getAllClients() {
    this.loadingTable = true;
    this.clientApi.GetClients().subscribe(async clientData => {
      if (clientData.status == 'success') {
        this.dataSource = await new MatTableDataSource(clientData.data.clients);
        this.dataSource.paginator = await this.paginator;
        setTimeout(() => {
          this.dataSource.sort = this.sort;

        }, 500);
        this.loadingTable = false;
      } else {
        this.loadingTable = false;
        this.snack.openSnackBar('Bir hata oluştu!', 'Warn');
      }
    }, err => {
      this.loadingTable = false;
      this.snack.openSnackBar(err.error.title, 'Error');
    });
  }
  ngOnInit(): void {

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  openDialog(name?: any, shortName?: any, logo?: any, id?: any, balance?: any, maxUploadSize?: number): void {
    const dialogRef = this.dialog.open(ClientDialogComponent, {
      width: '600px',
      height: 'auto',
      data: {
        id: id,
        title: 'Edit Client',
        name: name,
        shortName: shortName,
        balance: balance,
        logo: logo,
        maxUploadSize: maxUploadSize
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.event == 'success') {
        this.snack.openSnackBar('Başarılı.', 'Success');
        this.getAllClients();
      }
    });
  }

  openNewDialog() {
    const dialogRef = this.dialog.open(ClientDialogComponent, {
      width: '600px',
      height: 'auto',
      data: {
        title: 'New Client',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.event == 'success') {
        this.snack.openSnackBar('Başarılı.', 'Success');
        this.getAllClients();
      }
    });
  }

  deleteClient(id: any) {
    if (!confirm('Are you sure?')) return;
    this.clientApi.DeleteClient(Number(id)).subscribe(data => {
      if (data.status != 'success') {
        this.snack.openSnackBar('Hata oluştu!', 'Warn');
        this.getAllClients();
      } else {
        this.snack.openSnackBar('Başarılı', 'Success');
        this.getAllClients();
      }
    })
  }
}
