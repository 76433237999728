<vex-page-layout>
    <vex-page-layout-header class="pb-16 flex flex-col items-start justify-center">
        <div class="w-full flex flex-col sm:flex-row justify-between container">
            <div>
                <h1 class="title mt-0 mb-1">Log Table</h1>
                <vex-breadcrumbs [crumbs]="crumbs"></vex-breadcrumbs>
            </div>

        </div>
    </vex-page-layout-header>
    <vex-page-layout-content class="-mt-6">
        <div class="container -mt-16">
            <div class="card overflow-auto">

                <mat-tab-group mat-stretch-tabs="false" class="bg-app-bar" mat-align-tabs="start">
                    <mat-tab label="Admin Logs">
                        <div class="px-6 h-16 border-b sticky left-0 flex items-center">
                            <h2
                                class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
                                <span translate>Admin Logs</span>
                            </h2>
                            <div
                                class=" rounded-full px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
                                <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
                                <input (keyup)="applyFilterAdmin($event)"
                                    class="px-4 py-2 border-0 outline-none w-full bg-transparent"
                                    placeholder={{inputPlaceholder|translate}} type="search" #input>
                            </div>
                            <span class="flex-1"></span>
                        </div>
                        <table @stagger *ngIf="!loadingTable" mat-table [dataSource]="dataAdmin" matSort
                            #firstSort="matSort">
                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold">
                                    ID </th>
                                <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                            </ng-container>
                            <ng-container matColumnDef="mail">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="userMail"
                                    class="uppercase font-bold">
                                    <span>Mail</span>
                                </th>
                                <td *matCellDef="let row" class="w-8 min-w-8 pr-0 pt-1 pb-1" mat-cell>
                                    {{row.userMail}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="message">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold">
                                    <span>Message</span>
                                </th>
                                <td mat-cell *matCellDef="let row"> {{row.message }} </td>
                            </ng-container>
                            <ng-container matColumnDef="description">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold">
                                    <span>Description</span>
                                </th>
                                <td mat-cell *matCellDef="let row"> {{row.description }} </td>
                            </ng-container>

                            <ng-container matColumnDef="createDate">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="createdAt"
                                    class="uppercase font-bold">
                                    <span>Create Date</span>
                                </th>
                                <td mat-cell *matCellDef="let row"> {{row.createdAt|date: 'd/MM/yyyy H:mm'}} </td>
                            </ng-container>

                            <ng-container matColumnDef="type">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold">
                                    <span>Type</span>
                                </th>
                                <td mat-cell *matCellDef="let row"> 
                                    <ng-container [ngSwitch]="row.type">
                                        <mat-chip-list>
                                            <mat-chip *ngSwitchCase="'success'"  color="primary" selected>
                                            Success</mat-chip>
                                            <mat-chip *ngSwitchCase="'warn'" color="warn" selected>
                                            {{row.type}}</mat-chip>
                                            <mat-chip *ngSwitchCase="'error'" color="warn" selected>
                                            {{row.type}}</mat-chip>
                                            <mat-chip *ngSwitchCase="'info'" color="primary">
                                            {{row.type}}</mat-chip>
                                            </mat-chip-list>
                                    </ng-container>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr @fadeInUp mat-row *matRowDef="let row; columns: displayedColumns;"
                                class="hover:bg-hover trans-ease-out"></tr>
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="4" translate><span translate>No data matching the filter
                                        <span>"{{input.value}}"</span></span></td>
                            </tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of datas"
                            class="sticky left-0" translate>
                        </mat-paginator>
                    </mat-tab>
                    <mat-tab label="Client Logs">
                        <div class="px-6 h-16 border-b sticky left-0 flex items-center">
                            <h2
                                class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
                                <span translate>Client Logs</span>
                            </h2>
                            <div
                                class=" rounded-full px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
                                <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
                                <input (keyup)="applyFilterClient($event)" [(ngModel)]="clientSearch"
                                    class="px-4 py-2 border-0 outline-none w-full bg-transparent"
                                    placeholder={{inputPlaceholder|translate}} type="search" #input>
                            </div>
                            <span class="flex-1"></span>
                        </div>
                        <table @stagger *ngIf="!loadingTable" mat-table [dataSource]="dataClient" matSort
                            #secondSort="matSort">
                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold">
                                    ID </th>
                                <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                            </ng-container>
                            <ng-container matColumnDef="mail">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="userMail"
                                    class="uppercase font-bold">
                                    <span>Mail</span>
                                </th>
                                <td *matCellDef="let row" class="w-8 min-w-8 pr-0 pt-1 pb-1" mat-cell>
                                    {{row.userMail}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="message">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold">
                                    <span>Message</span>
                                </th>
                                <td mat-cell *matCellDef="let row"> {{row.message }} </td>
                            </ng-container>
                            <ng-container matColumnDef="description">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold">
                                    <span>Description</span>
                                </th>
                                <td mat-cell *matCellDef="let row"> {{row.description }} </td>
                            </ng-container>

                            <ng-container matColumnDef="createDate">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="createdAt"
                                    class="uppercase font-bold">
                                    <span>Create Date</span>
                                </th>
                                <td mat-cell *matCellDef="let row"> {{row.createdAt|date: 'd/MM/yyyy H:mm'}} </td>
                            </ng-container>

                            <ng-container matColumnDef="type">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold">
                                    <span>Type</span>
                                </th>
                                <td mat-cell *matCellDef="let row"> 
                                    <ng-container [ngSwitch]="row.type">
                                        <mat-chip-list>
                                            <mat-chip *ngSwitchCase="'success'" color="primary" selected>
                                            Success</mat-chip>
                                            <mat-chip *ngSwitchCase="'warn'" color="warn" selected>
                                            {{row.type}}</mat-chip>
                                            <mat-chip *ngSwitchCase="'error'" color="warn" selected>
                                            {{row.type}}</mat-chip>
                                            <mat-chip *ngSwitchCase="'info'" color="primary">
                                            {{row.type}}</mat-chip>
                                            </mat-chip-list>
                                    </ng-container>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr @fadeInUp mat-row *matRowDef="let row; columns: displayedColumns;"
                                class="hover:bg-hover trans-ease-out"></tr>
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="4" translate><span translate>No data matching the filter
                                        <span>"{{clientSearch}}"</span></span></td>
                            </tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" #paginatorClient="matPaginator"
                            aria-label="Select page of datas" class="sticky left-0" translate>
                        </mat-paginator>
                    </mat-tab>
                </mat-tab-group>

                <vex-loading [size]="100" *ngIf="loadingTable" class="w-100 flex justify-center items-center">
                </vex-loading>

            </div>
        </div>
    </vex-page-layout-content>
</vex-page-layout>