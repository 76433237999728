<h1 class="mat-dialog-title">{{data.title}}</h1>
<form [formGroup]="CompositionForm" class="flex flex-col" (submit)="saveDialog()">
    <mat-dialog-content>
        <mat-form-field appearance="outline" class="w-full">
            <mat-label translate>Name</mat-label>
            <input matInput type="text" formControlName="name" placeholder="Name">
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full">
            <mat-label translate>Title</mat-label>
            <input matInput type="text" formControlName="title" placeholder="Title">
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full">
            <mat-label translate>Description</mat-label>
            <textarea matInput type="text" cdkTextareaAutosize formControlName="description"
                placeholder="Description"></textarea>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
            <mat-label translate>Estimated Render Time</mat-label>
            <textarea matInput type="text" cdkTextareaAutosize formControlName="renderTime" placeholder="Estimated Render Time"></textarea>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
            <mat-label translate>Cost</mat-label>
            <textarea matInput type="text" cdkTextareaAutosize formControlName="cost" placeholder="Cost"></textarea>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full">
            <ngx-mat-file-input formControlName="thumbnailFile" placeholder="Thumbnail" valuePlaceholder="Thumbnail"
                accept=".png,.jpg,.jpeg" (ngModelChange)="changeImage($event)">
            </ngx-mat-file-input>
            <mat-icon matSuffix svgIcon="mat:folder"></mat-icon>
            <mat-error *ngIf="CompositionForm.get('thumbnailFile').hasError('maxContentSize')" translate>
                This file is too large to be uploaded.
                {{CompositionForm.get('thumbnailFile')?.getError('maxContentSize').maxSize |
                byteFormat}}
                ({{CompositionForm.get('thumbnailFile')?.getError('maxContentSize').actualSize
                | byteFormat}}).
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full">
            <ngx-mat-file-input formControlName="previewVideoFile" placeholder="Preview Video"
                valuePlaceholder="Preview Video" accept=".mp4,.mov,.webm,.avi">
            </ngx-mat-file-input>
            <mat-icon matSuffix svgIcon="mat:folder"></mat-icon>
            <mat-error *ngIf="CompositionForm.get('previewVideoFile').hasError('maxContentSize')" translate>
                This file is too large to be uploaded.
                {{CompositionForm.get('previewVideoFile')?.getError('maxContentSize').maxSize |
                byteFormat}}
                ({{CompositionForm.get('previewVideoFile')?.getError('maxContentSize').actualSize
                | byteFormat}}).
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full">
            <mat-label translate>Output Type</mat-label>
            <mat-select formControlName="outputType">
                <mat-option value="1">Video</mat-option>
                <mat-option value="2">Image</mat-option>
            </mat-select>
        </mat-form-field>

        <img [src]="newLogo" alt="thumbnailFile" class="w-full sm:max-w-[300px] mx-auto" *ngIf="newLogo">


    </mat-dialog-content>
    <div mat-dialog-actions class="flex justify-end">
        <button mat-raised-button color="warn" translate (click)="closeDialog()" type="button">Cancel</button>
        <button mat-raised-button color="primary" type="submit" [class.spinner]="loading"
            [disabled]="!CompositionForm.valid || loading" translate>Save</button>
    </div>
</form>