import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { TranslateService } from "@ngx-translate/core";

const componentName = "MatPaginatorIntlService";

/**
 * Utility service necessary to translate the mat-paginator
 * References:
 * https://material.angular.io/components/paginator/overview
 * https://stackoverflow.com/questions/46869616/how-to-use-matpaginatorintl
 */
@Injectable()
export class MatPaginationIntlService extends MatPaginatorIntl {
  constructor(private translateService: TranslateService) {
    super();


    // React whenever the language is changed
    this.translateService.onLangChange.subscribe((_event: Event) => {
      this.translateLabels();
    });

    // Initialize the translations once at construction time
    this.translateLabels();
  }


  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return this.translateService.instant('RANGE_PAGE_LABEL_1', { length });
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return this.translateService.instant('RANGE_PAGE_LABEL_2', { startIndex: startIndex + 1, endIndex, length });
  };

  translateLabels(): void {
    // this.firstPageLabel = this.translateService.instant("MAT_PAGINATOR.FIRST_PAGE");
    // this.itemsPerPageLabel = this.translateService.instant("MAT_PAGINATOR.ITEMS_PER_PAGE");
    // this.lastPageLabel = this.translateService.instant("MAT_PAGINATOR.LAST_PAGE");
    // this.nextPageLabel = this.translateService.instant("MAT_PAGINATOR.NEXT_PAGE");
    // this.previousPageLabel = this.translateService.instant("MAT_PAGINATOR.PREVIOUS_PAGE");
    this.itemsPerPageLabel = this.translateService.instant('ITEMS_PER_PAGE_LABEL');
    this.nextPageLabel = this.translateService.instant('NEXT_PAGE_LABEL');
    this.previousPageLabel = this.translateService.instant('PREVIOUS_PAGE_LABEL');
    this.firstPageLabel = this.translateService.instant('FIRST_PAGE_LABEL');
    this.lastPageLabel = this.translateService.instant('LAST_PAGE_LABEL');
    this.getRangeLabel = this.getRangeLabel.bind(this);
    this.changes.next(); // Fire a change event to make sure that the labels are refreshed
  }
}