import { Pipe, type PipeTransform } from '@angular/core';
import { ClientService } from '../services/client.service';

@Pipe({
  name: 'vexByteToMb',
  standalone: true,
})
export class ByteToMbPipe implements PipeTransform {
  constructor(private clientService: ClientService) { }

  transform(value: number): number {
    return this.clientService.ByteToMb(value);
  }

}
