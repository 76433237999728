<div class="dropdown">
  <div class="dropdown-header flex items-center justify-between">
    <div class="flex items-center">
      <div class="dropdown-heading-icon flex items-center justify-center">
        <mat-icon svgIcon="mat:person"></mat-icon>
      </div>
      <div class="dropdown-heading"> {{username}} </div>
    </div>

    <button [matMenuTriggerFor]="settingsMenu" mat-icon-button [matTooltip]="'Settings'|translate"
      matTooltipPosition="before" type="button">
      <mat-icon class="notifications-header-icon" svgIcon="mat:settings"></mat-icon>
    </button>
  </div>

  <div class="dropdown-content">
    <a (click)="close()" *ngFor="let item of items; trackBy: trackById" [routerLink]="['/profile']"
      class="notification flex items-center" matRipple>
      <mat-icon [svgIcon]="item.icon" [ngClass]="item.colorClass" class="notification-icon flex-none"></mat-icon>
      <div class="flex-auto">
        <div class="notification-label" [routerLink]="['/profile']">{{ item.label|translate }}</div>
        <div class="notification-description">{{ item.description|translate }}</div>
      </div>
      <mat-icon class="notification-chevron flex-none" svgIcon="mat:chevron_right"></mat-icon>
    </a>
  </div>
  

  <div class="dropdown-footer flex items-center justify-between">
    <div>
      <div class="py-3 flex items-center">
        <div class="py-3 flex items-center">
          <div
            class="w-10 h-10 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
            <mat-icon class="icon-sm creafinity-white-color" svgIcon="mat:monetization_on"></mat-icon>
          </div>
          <div class="flex flex-col ">
            <span class="font-bold" translate>Balance</span>
            <span>{{balance}}</span>
          </div>
        </div>
      </div>
    </div>
    <a (click)="logout()" class="creafinity-white-color" mat-button><span translate>Logout</span></a>
  </div>
</div>

<mat-menu #statusMenu="matMenu" xPosition="before" yPosition="below">
  <!-- <button (click)="setStatus(status)" *ngFor="let status of statuses; trackBy: trackById" mat-menu-item>
    <mat-icon [ngClass]="status.colorClass" [svgIcon]="status.icon"></mat-icon>
    <span>{{ status.label }}</span>
  </button> -->
</mat-menu>
<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <!-- <button mat-menu-item>
    <mat-icon svgIcon="mat:business"></mat-icon>
    <span>Change Address</span>
  </button> -->
  <!-- 
  <button mat-menu-item>
    <mat-icon svgIcon="mat:verified_user"></mat-icon>
    <span>Change Username</span>
  </button> -->

  <button mat-menu-item [routerLink]="['/profile']" (click)="close()">
    <mat-icon svgIcon="mat:lock"></mat-icon>
    <span translate>Change Password</span>
  </button>

  <!-- <button mat-menu-item>
    <mat-icon svgIcon="mat:notifications_off"></mat-icon>
    <span>Disable Notifications</span>
  </button> -->

</mat-menu>