<vex-page-layout>
    <vex-page-layout-header class="pb-16 flex flex-col items-start justify-center">
        <div class="w-full flex flex-col sm:flex-row justify-between container">
            <div>
                <h1 class="title mt-0 mb-1" translate>Templates Table</h1>
                <vex-breadcrumbs [crumbs]="crumbs"></vex-breadcrumbs>
            </div>
        </div>
    </vex-page-layout-header>
    <vex-page-layout-content class="-mt-6">
        <div class="container -mt-16">
            <div class="card overflow-auto">
                <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
                    <h2
                        class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
                        <span translate>Templates</span>
                    </h2>
                    <div
                        class="bg-foreground rounded-full px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
                        <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
                        <input (keyup)="applyFilter($event)"
                            class="px-4 py-2 border-0 outline-none w-full bg-transparent"
                            placeholder={{inputPlaceholder|translate}} type="search" #input>
                    </div>
                    <span class="flex-1"></span>
                    <button class="ml-4 flex-none" color="primary" mat-mini-fab matTooltip="Add Template" type="button"
                        (click)="openNewDialog()">
                        <mat-icon svgIcon="mat:add"></mat-icon>
                    </button>
                </div>
                <table @stagger *ngIf="!loadingTable" mat-table [dataSource]="dataSource" matSort #firstSort="matSort">
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold">
                            ID </th>
                        <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                    </ng-container>
                    <ng-container matColumnDef="thumbnail">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold">
                            <span translate>thumbnail</span>
                        </th>
                        <td *matCellDef="let row" class="w-8 min-w-8 pr-0 pt-1 pb-1" mat-cell>
                            <img [src]="row.thumbnail" class="avatar h-12 w-12 align-middle">
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold">
                            <span translate>Name</span>
                        </th>
                        <td mat-cell *matCellDef="let row"> {{row.name }} </td>
                    </ng-container>
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold">
                            <span translate>Description</span>
                        </th>
                        <td mat-cell *matCellDef="let row"> {{row.description }} </td>
                    </ng-container>
                    <ng-container matColumnDef="previewVideo">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold">
                            <span translate>Video</span>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <a [href]="row.previewVideo" target="_blank"
                                class="underline text-indigo-500">{{row.previewVideo }}</a>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="clientName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold">
                            <span translate>client name</span>
                        </th>
                        <td mat-cell *matCellDef="let row"> {{row.client?.name }} </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="uppercase font-bold" translate>
                            <span translate>Actions</span>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <button [matMenuTriggerFor]="actionMenu" mat-icon-button type="button" color="primary">
                                <mat-icon svgIcon="mat:more_vert"></mat-icon>
                            </button>
                            <mat-menu #actionMenu="matMenu" overlapTrigger="false" xPosition="before" yPosition="below">
                                <button mat-menu-item (click)="openDialog(row.id)">
                                    <mat-icon svgIcon="mat:edit" color="primary"></mat-icon>
                                    <span translate>Edit Template</span>
                                </button>
                                <button mat-menu-item (click)="calculateCost(row.id)">
                                    <mat-icon svgIcon="mat:attach_money" color="primary"></mat-icon>
                                    <span translate>Calculate Cost</span>
                                </button>
                                <button mat-menu-item (click)="openComposition(row.id)">
                                    <mat-icon svgIcon="mat:sort" color="accent"></mat-icon>
                                    <span translate>Compositions</span>
                                </button>
                                <button mat-menu-item (click)="deleteTemplate(row.id)">
                                    <mat-icon svgIcon="mat:delete" color="warn"></mat-icon>
                                    <span translate>Delete Template</span>
                                </button>
                            </mat-menu>

                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr @fadeInUp mat-row *matRowDef="let row; columns: displayedColumns;"
                        class="hover:bg-hover trans-ease-out"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4" translate><span translate>No data matching the filter
                                <span>"{{input.value}}"</span></span></td>
                    </tr>
                </table>
                <vex-loading [size]="100" *ngIf="loadingTable" class="w-100 flex justify-center items-center">
                </vex-loading>
                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of datas"
                    class="sticky left-0" translate>
                </mat-paginator>
            </div>
        </div>
    </vex-page-layout-content>
</vex-page-layout>