import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'vex-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss']
})
export class CustomSnackbarComponent implements OnInit {
  iconClass: string = '';

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.getIcon
  }

  get getIcon() {
    switch (this.data.snackType) {
      case 'Success':
        this.iconClass = 'w-10 h-10 rounded-full bg-green/10 text-green ltr:mr-3 rtl:ml-3 flex items-center justify-center'
        return 'mat:done';
      case 'Error':
        this.iconClass = 'w-10 h-10 rounded-full bg-warn/10 text-warn ltr:mr-3 rtl:ml-3 flex items-center justify-center'
        return 'mat:error';
      case 'Warn':
        this.iconClass = 'w-10 h-10 rounded-full bg-warn/10 text-warn ltr:mr-3 rtl:ml-3 flex items-center justify-center'
        return 'mat:warning';
      case 'Info':
        this.iconClass = 'w-10 h-10 rounded-full bg-accent/10 text-accent ltr:mr-3 rtl:ml-3 flex items-center justify-center'
        return 'mat:info';
    }
  }
}
