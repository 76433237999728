import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomSnackbarServiceService } from 'src/@vex/components/custom-snackbar/custom-snackbar-service.service';
import { AccountsService } from 'src/@vex/services/accounts.service';
import { BrandsService } from 'src/@vex/services/brands.service';
import { CategoriesService } from 'src/@vex/services/categories.service';
import { ClientService } from 'src/@vex/services/client.service';
import { CompaniesService } from 'src/@vex/services/companies.service';

@Component({
  selector: 'vex-accounts-dialog',
  templateUrl: './accounts-dialog.component.html',
  styleUrls: ['./accounts-dialog.component.scss']
})
export class AccountsDialogComponent implements OnInit {

  AccountForm: FormGroup;
  loading: boolean = false;
  allAccountPermission: any;
  companies: any;
  brands: any;
  categories: any;
  clients: any;
  oldClient: number;
  companyIds: any[] = [];
  brandIds: any[] = [];
  categoriesIds: any[] = [];


  constructor(
    public dialogRef: MatDialogRef<AccountsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: AccountsService,
    private snack: CustomSnackbarServiceService,
    private brandApi: BrandsService,
    private companyApi: CompaniesService,
    private categoriesApi: CategoriesService,
    private clientApi: ClientService
  ) {
    if (this.data.title == 'New Account') {
      this.clientApi.GetClients().subscribe(clientData => {
        this.clients = clientData.data.clients;
      })
      this.AccountForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]),
        fullName: new FormControl('', Validators.required),
        roleMap: new FormControl(''),
        user: new FormControl(true),
        admin: new FormControl(false),
        manager: new FormControl(false),
        clientId: new FormControl('', Validators.required)
      })
    } else {
      this.clientApi.GetClients().subscribe(clientData => {
        this.clients = clientData.data.clients;
      })
      this.api.GetAccountDetail(this.data.id).subscribe(async data => {
        console.log(data);
        this.allAccountPermission = await data.data.account.user;
        this.oldClient = data.data.account.user.client.id;
        this.returnCompanyId(await this.allAccountPermission?.companyMaps);
        this.returnBrandId(await this.allAccountPermission?.brandMaps);
        this.returnCategoriesId(await this.allAccountPermission?.categoryMaps);
      })

      this.companyApi.GetCompanies().subscribe(async data => {
        this.companies = await data.data.companies;
        this.allAccountPermission?.companyMaps.map(x => {
          for (let index = 0; index < this.companies.length; index++) {
            if (x.company.id == this.companies[index].id) {
              this.companies[index]['disabled'] = true;
            } else {
              this.companies[index]['disabled'] = false;
            }
          }
        })
      });
      this.brandApi.GetBrands().subscribe(async data => {
        this.brands = await data.data.brands
        this.allAccountPermission?.brandMaps.map(x => {
          for (let index = 0; index < this.brands.length; index++) {
            if (x.brand.id == this.brands[index].id) {
              this.brands[index]['disabled'] = true;
            } else {
              this.brands[index]['disabled'] = false;
            }
          }
        })
      });
      this.categoriesApi.GetCategories().subscribe(async data => {
        this.categories = await data.data.categories
        this.allAccountPermission?.categoryMaps.map(x => {
          for (let index = 0; index < this.categories.length; index++) {
            if (x.category.id == this.categories[index].id) {
              this.categories[index]['disabled'] = true;
            } else {
              this.categories[index]['disabled'] = false;
            }
          }
        })
      });
      this.AccountForm = new FormGroup({
        email: new FormControl(this.data.email, [Validators.required, Validators.email]),
        password: new FormControl(''),
        fullName: new FormControl(this.data.fullName, Validators.required),
        roles: new FormControl(this.data.roles),
        company: new FormControl(null),
        brand: new FormControl(null),
        category: new FormControl(null),
        user: new FormControl(true),
        admin: new FormControl(false),
        manager: new FormControl(false),
        clientId: new FormControl(this.oldClient, Validators.required)
      })
      setTimeout(() => {
        this.AccountForm.get('clientId')?.setValue(this.oldClient);
      }, 300);
    }
  }

  returnCompanyId(data) {
    if (data) {
      data.map(x => {
        this.companyIds.push(x.company.id);
      })
    }
    this.AccountForm.get('company').setValue(this.companyIds);
  }

  returnBrandId(data) {
    if (data) {
      data.map(x => {
        this.brandIds.push(x.brand.id);
      })
    }
    this.AccountForm.get('brand').setValue(this.brandIds);

  }

  returnCategoriesId(data) {
    if (data) {
      data.map(x => {
        this.categoriesIds.push(x.category.id);
      })
    }
    this.AccountForm.get('category').setValue(this.categoriesIds);
  }


  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close({ event: 'close' });
  }

  saveDialog() {
    this.loading = true;
    if (this.data.title == 'New Account') {

      let roles = [];

      if (this.AccountForm.get('user').value == true) {
        roles.push({ roleId: 3 });
      }

      if (this.AccountForm.get('admin').value == true) {
        roles.push({ roleId: 5 });
      }

      if (this.AccountForm.get('manager').value == true) {
        roles.push({ roleId: 1 });
      }

      let newData = {
        email: this.AccountForm.get('email').value,
        password: this.AccountForm.get('password').value,
        fullName: this.AccountForm.get('fullName').value,
        user: (this.AccountForm.get('user').value == true) ? { 'clientId': this.AccountForm.get('clientId').value } : null,
        admin: (this.AccountForm.get('admin').value == true) ? {} : null,
        manager: (this.AccountForm.get('manager').value == true) ? {} : null,
        roles: roles
      };

      this.api.PostAccount(newData).subscribe(data => {
        this.loading = false;
        if (data.status != 'success') {
          this.snack.openSnackBar('Tekrar deneyiniz!', 'Warn');
        } else {
          this.companyApi.GetCompaniesId(this.AccountForm.get('clientId').value).subscribe({
            next: async (companyData) => {
              let companies: any[] = [];
              await companyData.data.companies.map(c => companies.push(c.id));
              if (companies.length != 0) {
                await this.api.UserUpdateCompanies({ accountId: data.data, companyIds: companies }).subscribe({
                  next: async (updateCompanyData) => {
                    await this.dialogRef.close({ event: 'success' });
                  }
                })
              } else {
                this.dialogRef.close({ event: 'success' });
              }
            }
          })
        }
      }, err => {
        this.loading = false;
        console.error(err);
        this.snack.openSnackBar('Bir hata oluştu!', 'Error');
      })

    } else {
      let roles = [];
      if (this.AccountForm.get('user').value == true) {
        roles.push({ roleId: 3 });
      }

      if (this.AccountForm.get('admin').value == true) {
        roles.push({ roleId: 5 });
      }

      if (this.AccountForm.get('manager').value == true) {
        roles.push({ roleId: 1 });
      }

      let editData = {
        email: this.AccountForm.get('email').value,
        password: this.AccountForm.get('password').value,
        fullName: this.AccountForm.get('fullName').value,
        id: this.data.id,
        user: {
          clientId: this.AccountForm.get('clientId').value
        },
        admin: {},
        roles: roles
      };

      this.api.PutAccount(editData).subscribe(data => {
        let updateBrand = {
          accountId: this.data.id,
          brandIds: this.AccountForm.get('brand').value
        }

        this.api.UserUpdateBrands(updateBrand).subscribe(x => {
          if (x.status == 'success') {
            let updateCategories = {
              accountId: this.data.id,
              categoryIds: this.AccountForm.get('category').value
            }
            this.api.UserUpdateCategories(updateCategories).subscribe(y => {
              if (y.status == 'success') {
                let updateCompanies = {
                  accountId: this.data.id,
                  companyIds: this.AccountForm.get('company').value
                }
                this.api.UserUpdateCompanies(updateCompanies).subscribe(z => {
                  if (z.status == 'success') {
                    this.dialogRef.close({ event: 'success' });
                  } else {
                    console.warn(x);
                    this.snack.openSnackBar('Tekrar deneyiniz', 'Warn');
                  }
                }, err => {
                  this.loading = false;
                  console.warn(err);
                  this.snack.openSnackBar('Hata!', 'Error');
                })
              } else {
                console.warn(x);
                this.snack.openSnackBar('Tekrar deneyiniz', 'Warn');
              }
            }, err => {
              this.loading = false;
              console.warn(err);
              this.snack.openSnackBar('Hata!', 'Error');
            })
          } else {
            console.warn(x);
            this.snack.openSnackBar('Tekrar deneyiniz', 'Warn');
          }
        }, err => {
          this.loading = false;
          console.warn(err);
          this.snack.openSnackBar('Hata!', 'Error');
        })
      })
    }
  }

  changeClient() {
    if (this.AccountForm.get('user').value) {
      this.AccountForm.get('clientId').setValidators(Validators.required);
    } else {
      this.AccountForm.get('clientId').clearValidators();
      this.AccountForm.get('clientId').updateValueAndValidity();
    }
  }



}
