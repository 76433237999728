import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from './custom-snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class CustomSnackbarServiceService {

  constructor(private snackBar: MatSnackBar) { }
  public openSnackBar(message: string, snackType?: any) {
    const _snackType: any =
      snackType !== undefined ? snackType : 'Success';

    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      duration: 3000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      data: { message: message, snackType: _snackType }
    });
  }
}
