import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../../@vex/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }

  tokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentUser = this.auth.currentUser;
    this.auth.TokenIsValid().then(x => {
      if (!x) {
        this.auth.Logout();
        return false;
      }
    });

    if (currentUser) {
      if (this.auth.credInfo != '') {
        if (!this.tokenExpired(this.auth.credInfo.data.token)) {
          return true;
        } else {
          this.auth.Logout();
          return false;
        }
      }
      this.auth.Logout();
      return false;
    }
    this.auth.Logout();
    return false;
  }

}
