import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileValidator } from 'ngx-material-file-input';
import { CustomSnackbarServiceService } from 'src/@vex/components/custom-snackbar/custom-snackbar-service.service';
import { BrandsService } from 'src/@vex/services/brands.service';
import { CompaniesService } from 'src/@vex/services/companies.service';

@Component({
  selector: 'vex-brands-dialog',
  templateUrl: './brands-dialog.component.html',
  styleUrls: ['./brands-dialog.component.scss']
})
export class BrandsDialogComponent implements OnInit {

  BrandForm: FormGroup;
  newLogo: string | SafeUrl = '';
  loading: boolean = false;
  companiesData: any;

  constructor(
    public dialogRef: MatDialogRef<BrandsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer,
    private api: BrandsService,
    private companyApi: CompaniesService,
    private snack: CustomSnackbarServiceService
  ) {
    this.companyApi.GetCompanies().subscribe(async data => {
      this.companiesData = await data.data.companies;
    })
    if (this.data.logo) {
      this.newLogo = this.data.logo;
    }
    if (this.data.title == 'New Brand') {
      this.BrandForm = new FormGroup({
        name: new FormControl(this.data.name, Validators.required),
        description: new FormControl(this.data.description, Validators.required),
        logo: new FormControl('', [FileValidator.maxContentSize(5242880), Validators.required]),
        companyId: new FormControl('', Validators.required)
      })
    } else {
      this.BrandForm = new FormGroup({
        name: new FormControl(this.data.name, Validators.required),
        description: new FormControl(this.data.description, Validators.required),
        logo: new FormControl('', FileValidator.maxContentSize(5242880)),
        companyId: new FormControl(this.data.companyId, Validators.required)
      })
    }
  }

  ngOnInit(): void {
  }

  saveDialog() {
    this.loading = true;
    let formData: any = new FormData();
    formData.append('Id', this.data.id);
    formData.append('Name', this.BrandForm.get('name').value);
    formData.append('Description', this.BrandForm.get('description').value);
    formData.append('CompanyId', this.BrandForm.get('companyId').value);
    if (this.BrandForm.get('logo').value != '') {
      formData.append('LogoFile', this.BrandForm.get('logo').value.files[0])
    }
    if (this.data.title == 'Edit Brand') {
      this.api.PutBrand(formData).subscribe(data => {
        this.loading = false;
        if (data.status != 'success') {
          this.snack.openSnackBar('Tekrar deneyiniz!', 'Warn');
        } else {
          this.dialogRef.close({ event: 'success' });
        }
      }), err => {
        this.loading = false;
        console.error(err);
        this.snack.openSnackBar('Bir hata oluştu!', 'Error');
      }
    } else {
      this.api.PostBrand(formData).subscribe(data => {
        this.loading = false;
        if (data.status != 'success') {
          console.warn(data);
          this.snack.openSnackBar('Tekrar deneyiniz!', 'Warn');
        } else {
          this.dialogRef.close({ event: 'success' });
        }
      }), err => {
        this.loading = false;
        console.error(err);
        this.snack.openSnackBar('Bir hata oluştu!', 'Error');
      }
    }

  }

  closeDialog() {
    this.dialogRef.close({ event: 'close' });
  }
  changeImage(ev: any) {
    this.newLogo = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(ev.files[0])
    );
  }

}
