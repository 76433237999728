import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { AuthService } from 'src/@vex/services/auth.service';
import { ProfileService } from 'src/@vex/services/profile.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CustomSnackbarServiceService } from 'src/@vex/components/custom-snackbar/custom-snackbar-service.service';
import { _SnackBarContainer } from '@angular/material/snack-bar';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { TemplateService } from 'src/@vex/services/template.service';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'vex-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  animations: [
    fadeInUp400ms,
    fadeInRight400ms,
    scaleIn400ms,
    stagger40ms
  ]
})
export class ProfileComponent implements OnInit {

  detailTip: string = "Detay";
  username: string = '';
  typeInput: string = 'password';
  newUsername: any = '';
  newPassword: any = '';
  oldPassword: any = '';
  loadingTable: boolean = true;
  confirmPassword: any = '';
  checkIdArray: any[] = [];
  oldPasswordShow: boolean = true;
  newPasswordShow: boolean = true;
  confirmPasswordShow: boolean = true;
  username2: any = '';
  displayedColumns: string[] = ['id', 'title', 'createDate', 'actions'];
  dataSource: MatTableDataSource<any>;
  renderCheckArray: any;
  balance: any;
  loadingButton: boolean = false;
  loadingUsername: boolean = false;

  @ViewChild(MatSort) sort: MatSort;


  constructor(
    private readonly auth: AuthService,
    private profileServices: ProfileService,
    private snack: CustomSnackbarServiceService,
    private router: Router,
    private templateApi: TemplateService) {
    this.templateApi.GetRenders().subscribe(async renderData => {
      if (renderData.status == 'success') {
        this.renderCheckArray = await renderData.data.renderRequests;
        this.dataSource = await new MatTableDataSource(renderData.data.renderRequests.slice(Math.max(this.renderCheckArray.length - 5, 0)));
        setTimeout(() => {
          this.dataSource.sort = this.sort;
        }, 500);
        this.checkRenderStatus();
        this.removeNonRender();
      } else {
        this.loadingTable = false;
        this.snack.openSnackBar(renderData.message, 'Warn');
      }
    }, err => {
      this.loadingTable = false;
      this.snack.openSnackBar(err.error.title, 'Error');
    })
  }



  checkRenderStatus() {
    this.renderCheckArray.map(async x => {
      await this.templateApi.GetRenderDetail(x.id).subscribe(async data => {
        if (data.data.renderRequest.dataItems[0].medias.length != 0) {
          await this.checkIdArray.push(x.id);
          this.loadingTable = false;
        }
      })
    })
  }
  doneIdArray: any[] = [];
  removeNonRender() {
    this.renderCheckArray.map(async x => {
      await this.templateApi.GetRenderDetail(x.id).subscribe(async data => {
        if (data.data.renderRequest.dataItems[0].medias.length == 0) {
          await this.doneIdArray.push(x.id);
          this.loadingTable = false;
        }
      })
    })
  }

  //Password FormGroup
  newUserInfo = new FormGroup({
    newPass: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]),
    rePassword: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(20)]),
    oldPassword: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(20)])
  })

  //Username FormGroup
  newUsernameForm = new FormGroup({
    newUser: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(20), Validators.nullValidator])
  })


  public cleanForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => formGroup.get(key).setValue(formGroup.get(key).value.replace(/\s/g, '')
    ));
  }

  ngOnInit(): void {
    this.username = this.auth.userName;
    this.balance = this.auth.balanceInfo;
    this.username2 = this.auth.currentUser;
  }

  saveUsername() {
    this.loadingUsername = true;
    this.profileServices.updateUsername(this.newUsernameForm.controls['newUser'].value).subscribe(x => {
      if (x.status == "success") {
        this.snack.openSnackBar('Kullanıcı adı başarıyla güncellendi.', 'Success');
        setTimeout(() => {
          this.auth.Logout();
          this.loadingUsername = false;
        }, 2100);
      } else {
        this.snack.openSnackBar('Tekrar deneyiniz.', 'Warn');
        this.loadingUsername = false;
      }
    })
  }

  savePassword() {
    this.loadingButton = true;
    this.profileServices.updatePassword(this.newUserInfo.controls['oldPassword'].value, this.newUserInfo.controls['rePassword'].value).subscribe(y => {
      if (y.status != "success") {
        this.loadingButton = false;
        this.snack.openSnackBar(y.message, "Warn");
      } else {
        this.snack.openSnackBar('Şifre başarılı bir şekilde güncellendi.', 'Success');
        setTimeout(() => {
          window.location.reload();
          this.loadingButton = false;
        }, 2100);
      }
    });
  }

  navigateRenderDetail(id: number) {
    this.templateApi.GetRenderDetail(id).subscribe(x => {
      if (x.data.renderRequest == null || x.data.renderRequest.dataItems[0].medias.length == 0 || x.data.renderRequest.dataItems.legth == 0) {
        return this.snack.openSnackBar('Render işlemi tamamlanmamıştır. Daha sonra tekrar deneyiniz', 'Warn');
      } else {
        localStorage.setItem('crumbId', String(id));
        return this.router.navigate(
          ['render-detail'],
          {
            queryParams: { id: id }
          }
        )
      }
    });

  }








}
