import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(private http: HttpClient) { }

  GetAccounts(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + '/Accounts?page=1&size=999999999');
  }

  GetAccountRoles(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + '/Accounts/GetAccountRoles');
  }

  DeleteAccount(id: any): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + '/Accounts/' + id);
  }

  PostAccount(data: any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/Accounts', data);
  }

  PutAccount(data: any): Observable<any> {
    return this.http.put<any>(environment.apiUrl + '/Accounts', data);
  }

  GetAccountDetail(id: any): Observable<any> {
    return this.http.get<any>(environment.apiUrl + '/Accounts/' + id);
  }

  UserUpdateCompanies(data: any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/User/updateCompanies', data);
  }

  UserUpdateCategories(data: any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/User/updateCategories', data);
  }

  UserUpdateBrands(data: any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/User/updateBrands', data);
  }
}
