import { Component, Input, OnInit } from '@angular/core';
import { trackByValue } from '../../utils/track-by';

@Component({
  selector: 'vex-breadcrumbs',
  template: `
 <div class="flex items-center" translate>
      <vex-breadcrumb translate>
        <a [routerLink]="['/']" translate>
          <mat-icon svgIcon="mat:home" class="icon-sm"></mat-icon>
        </a>
      </vex-breadcrumb>
      <ng-container *ngFor="let crumb of crumbs; trackBy: trackByValue">
        <div class="w-1 h-1 rounded-full ltr:mr-2 rtl:ml-2 bg-gray"></div>
        <vex-breadcrumb *ngIf="crumb.queryParamsValue">
          <a [routerLink]="[crumb.link]" translate [queryParams]="{id: queryParamsChanged2}" >{{crumb.name|translate}}</a>
        </vex-breadcrumb>
        <vex-breadcrumb *ngIf="!crumb.queryParamsValue">
          <a [routerLink]="[crumb.link]">{{crumb.name|translate}}</a>
        </vex-breadcrumb>
      </ng-container>
    </div>
  `
})
export class BreadcrumbsComponent implements OnInit {

  @Input() crumbs: any[] = [];

  trackByValue = trackByValue;
  queryParamsChanged1: any;
  queryParamsChanged2: any;

  constructor() {

  }

  ngOnInit() {
    this.crumbs.map(x => {
      if (x.queryParamsValue) {
        this.queryParamsChanged1 = x.queryParams.split(':')[0];
        this.queryParamsChanged2 = x.queryParams.split(':')[1];
      }
    })
  }
}
