import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileValidator } from 'ngx-material-file-input';
import { CustomSnackbarServiceService } from 'src/@vex/components/custom-snackbar/custom-snackbar-service.service';
import { ClientService } from 'src/@vex/services/client.service';

@Component({
  selector: 'vex-client-dialog',
  templateUrl: './client-dialog.component.html',
  styleUrls: ['./client-dialog.component.scss']
})
export class ClientDialogComponent implements OnInit {

  ClientForm: FormGroup;
  newLogo: string | SafeUrl = '';
  loading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ClientDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer,
    private api: ClientService,
    private snack: CustomSnackbarServiceService,
    private clientService: ClientService
  ) {
    if (this.data.logo) {
      this.newLogo = this.data.logo;
    }
    if (this.data.title == 'New Client') {
      this.ClientForm = new FormGroup({
        name: new FormControl('', Validators.required),
        shortName: new FormControl('', Validators.required),
        balance: new FormControl('', [Validators.pattern("^[0-9]*$"), Validators.required]),
        logo: new FormControl('', [FileValidator.maxContentSize(5242880), Validators.required]),
        maxUploadSize: new FormControl('', Validators.required)
      })
    } else {
      this.ClientForm = new FormGroup({
        name: new FormControl(this.data.name, Validators.required),
        shortName: new FormControl(this.data.shortName, Validators.required),
        balance: new FormControl(this.data.balance, [Validators.pattern("^[0-9]*$"), Validators.required]),
        logo: new FormControl('', FileValidator.maxContentSize(5242880)),
        maxUploadSize: new FormControl(this.clientService.ByteToMb(this.data.maxUploadSize), Validators.required)
      })
    }
  }

  ngOnInit(): void {
  }

  saveDialog() {
    this.loading = true;
    let formData: any = new FormData();
    formData.append('Id', this.data.id);
    formData.append('Name', this.ClientForm.get('name').value);
    formData.append('ShortName', this.ClientForm.get('shortName').value);
    formData.append('Balance', this.ClientForm.get('balance').value);
    formData.append('MaxUploadSize', this.clientService.MbToByte(Number(this.ClientForm.get('maxUploadSize').value)));

    if (this.ClientForm.get('logo').value != '') {
      formData.append('LogoFile', this.ClientForm.get('logo').value.files[0])
    }
    if (this.data.title == 'Edit Client') {
      this.api.PutClients(formData).subscribe(data => {
        this.loading = false;
        if (data.status != 'success') {
          this.snack.openSnackBar('Tekrar deneyiniz!', 'Warn');
        } else {
          this.dialogRef.close({ event: 'success' });
        }
      }), err => {
        this.loading = false;
        console.error(err);
        this.snack.openSnackBar('Bir hata oluştu!', 'Error');
      }
    } else {
      this.api.PostClient(formData).subscribe(data => {
        this.loading = false;
        if (data.status != 'success') {
          this.snack.openSnackBar('Tekrar deneyiniz!', 'Warn');
        } else {
          this.dialogRef.close({ event: 'success' });
        }
      }), err => {
        this.loading = false;
        console.error(err);
        this.snack.openSnackBar('Bir hata oluştu!', 'Error');
      }
    }

  }

  closeDialog() {
    this.dialogRef.close({ event: 'close' });
  }
  changeImage(ev: any) {
    this.newLogo = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(ev.files[0])
    );
  }
}
