import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomSnackbarServiceService } from 'src/@vex/components/custom-snackbar/custom-snackbar-service.service';
import { TemplatesService } from 'src/@vex/services/templates.service';
import { COMMA, ENTER } from "@angular/cdk/keycodes";

@Component({
  selector: 'vex-recent-uploaded-templates-dialog',
  templateUrl: './recent-uploaded-templates-dialog.component.html',
  styleUrls: ['./recent-uploaded-templates-dialog.component.scss']
})

export class RecentUploadedTemplatesDialogComponent implements OnInit {
  TemplateForm: FormGroup;
  loading: boolean = false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  progressBar: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<RecentUploadedTemplatesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: TemplatesService,
    private snack: CustomSnackbarServiceService,
    private fb: FormBuilder,
  ) {
    console.log(this.data);
    this.TemplateForm = this.fb.group({
      description: this.fb.control(this.data.description),
      status: this.fb.control(this.data.status, Validators.required),
    })
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close({ event: 'close' });
  }


  saveDialog() {
    this.loading = true;
    this.progressBar = true;
    const formData = new FormData();

    formData.append('Id', this.data.id);
    formData.append('Name', this.data.name);
    formData.append('CampaignName', this.data.campaignName);
    formData.append('Url', this.data.url);
    formData.append('UserId', this.data.userID);
    formData.append('ClientId', this.data.clientId);
    formData.append('Description', this.TemplateForm.get('description').value);
    formData.append('Status', this.TemplateForm.get('status').value);

    this.api.UpdateUploadedTemplateStatus(formData).subscribe(data => {
      this.loading = false;
      this.progressBar = false;
      if (data.status == 'success') {
        this.dialogRef.close({ event: 'success' });
      } else {
        this.snack.openSnackBar('Tekrar deneyiniz!', 'Warn');
      }
    }, err => {
      console.warn(err);
      this.loading = false;
      this.progressBar = false;
      this.snack.openSnackBar('Bir hata oluştu!', 'Error');
    })
  }

}
