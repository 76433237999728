<h1 class="mat-dialog-title">{{data.title}}</h1>
<form [formGroup]="CategoryForm" class="flex flex-col">
    <mat-dialog-content>
        <mat-form-field appearance="outline" class="w-full">
            <mat-label translate>Name</mat-label>
            <input matInput type="text" formControlName="name" [placeholder]="'Name' | translate">
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full">
            <mat-label>Brand Name</mat-label>
            <mat-select formControlName="brandId">
                <mat-option *ngFor="let item of brandsData" [value]="item.id">
                    {{item.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full">
            <mat-label translate>Description</mat-label>
            <textarea matInput formControlName="desc" [placeholder]="'Description' | translate"></textarea>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-full">
            <ngx-mat-file-input formControlName="logoFile" placeholder="Logo" valuePlaceholder="logo"
                accept=".png,.jpg,.jpeg" (ngModelChange)="changeImage($event)">
            </ngx-mat-file-input>
            <mat-icon matSuffix svgIcon="mat:folder"></mat-icon>
            <mat-error *ngIf="CategoryForm.get('logoFile').hasError('maxContentSize')" translate>
                This file is too large to be uploaded.
                {{CategoryForm.get('logoFile')?.getError('maxContentSize').maxSize |
                byteFormat}}
                ({{CategoryForm.get('logoFile')?.getError('maxContentSize').actualSize
                | byteFormat}}).
            </mat-error>
        </mat-form-field>

        <img [src]="newLogo" alt="Logo" class="w-full sm:max-w-[300px] mx-auto" *ngIf="newLogo">
    </mat-dialog-content>
    <div mat-dialog-actions class="flex justify-end">
        <button mat-raised-button color="warn" translate (click)="closeDialog()">Cancel</button>
        <button mat-raised-button color="primary" (click)="saveDialog()" [class.spinner]="loading"
            [disabled]="!CategoryForm.valid || loading" translate>Save</button>
    </div>
</form>