import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileValidator } from 'ngx-material-file-input';
import { CustomSnackbarServiceService } from 'src/@vex/components/custom-snackbar/custom-snackbar-service.service';
import { BrandsService } from 'src/@vex/services/brands.service';
import { CategoriesService } from 'src/@vex/services/categories.service';

@Component({
  selector: 'vex-categories-dialog',
  templateUrl: './categories-dialog.component.html',
  styleUrls: ['./categories-dialog.component.scss']
})
export class CategoriesDialogComponent implements OnInit {

  CategoryForm: FormGroup;
  newLogo: string | SafeUrl = '';
  loading: boolean = false;
  brandsData: any;

  constructor(
    public dialogRef: MatDialogRef<CategoriesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer,
    private api: CategoriesService,
    private brandApi: BrandsService,
    private snack: CustomSnackbarServiceService
  ) {
    this.brandApi.GetBrands().subscribe(async data => {
      this.brandsData = await data.data.brands;
    })
    if (this.data.logo) {
      this.newLogo = this.data.logo;
    }
    if (this.data.title == 'New Brand') {
      this.CategoryForm = new FormGroup({
        name: new FormControl(this.data.name, Validators.required),
        desc: new FormControl('', Validators.required),
        brandId: new FormControl('', Validators.required),
        logoFile: new FormControl('')
      })
    } else {
      this.CategoryForm = new FormGroup({
        name: new FormControl(this.data.name, Validators.required),
        desc: new FormControl(this.data.description, Validators.required),
        brandId: new FormControl(this.data.brandId, Validators.required),
        logoFile: new FormControl('')
      })
    }
  }

  ngOnInit(): void {
  }

  saveDialog() {
    this.loading = true;
    let formData: any = new FormData();
    formData.append('Id', this.data.id);
    formData.append('Name', this.CategoryForm.get('name').value);
    formData.append('BrandId', this.CategoryForm.get('brandId').value);
    formData.append('Description', this.CategoryForm.get('desc').value);
    if (this.CategoryForm.get('logoFile').value != '') {
      formData.append('LogoFile', this.CategoryForm.get('logoFile').value.files[0])
    }
    if (this.data.title == 'Edit Category') {
      this.api.PutCategory(formData).subscribe(data => {
        this.loading = false;
        if (data.status != 'success') {
          this.snack.openSnackBar('Tekrar deneyiniz!', 'Warn');
        } else {
          this.dialogRef.close({ event: 'success' });
        }
      }), err => {
        this.loading = false;
        console.error(err);
        this.snack.openSnackBar('Bir hata oluştu!', 'Error');
      }
    } else {
      this.api.PostCategory(formData).subscribe(data => {
        this.loading = false;
        if (data.status != 'success') {
          this.snack.openSnackBar('Tekrar deneyiniz!', 'Warn');
        } else {
          this.dialogRef.close({ event: 'success' });
        }
      }), err => {
        this.loading = false;
        console.error(err);
        this.snack.openSnackBar('Bir hata oluştu!', 'Error');
      }
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: 'close' });
  }

  changeImage(ev: any) {
    this.newLogo = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(ev.files[0])
    );
  }
}
